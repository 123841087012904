import { useEffect } from "react";
import { useTireSizeStore } from "./store/tire-size-store";
import useConditionalSelection, {
  createSelectionField,
  createSelectionOption,
} from "./util/useConditionalSelection";

const useSelectCustomTireSize = (
  onSubmit = (tireSize) =>
    console.warn(
      "Warning: You need to pass onSubmit function to useSelectCustomTireSize",
      tireSize
    )
) => {
  const [getTireSizeStoreAsync, tireSizeController] = useTireSizeStore((st) => [
    st.getTireSizeStoreAsync,
    st.tireSizeController,
  ]);

  const getInitialSelection = async () => {
    const { userTireSize: ts } = await getTireSizeStoreAsync();
    return [ts.width, ts.ratio, ts.rim].filter((i) => i);
  };

  const createOption = (arr) =>
    arr.map((val) => createSelectionOption(val, val));

  const getWidths = async () => {
    const widths = await tireSizeController();
    return createOption(widths);
  };

  const getRatios = async ([width]) => {
    const ratios = await tireSizeController({ width });
    return createOption(ratios);
  };

  const getRims = async ([width, ratio]) => {
    const rims = await tireSizeController({ width, ratio });
    return createOption(rims);
  };

  const getFinalSelection = async ([width, ratio, rim]) => {
    const selection = await tireSizeController({ width, ratio, rim });
    return selection;
  };

  const onUserSubmit = async (tireSize) => {
    const userTireSize = await tireSizeController(tireSize);
    if (userTireSize) {
      userTireSize.setSelected();
      onSubmit(userTireSize);
    }
  };

  const state = useConditionalSelection(
    getInitialSelection,
    [
      createSelectionField("Width", "width", getWidths),
      createSelectionField("Ratio", "ratio", getRatios),
      createSelectionField("Rim", "rim", getRims),
    ],
    getFinalSelection,
    onUserSubmit
  );

  useEffect(() => {
    state.handleInit();
  }, []);

  return state;
};

export default useSelectCustomTireSize;
