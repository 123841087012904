import {
  Tabs,
  Tab,
  Box,
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import useSelectTireSizeByVehicle from "../../../hooks/useSelectTireSizeByVehicle";
import SelectCustomTireSize from "./SelectCustomTireSize";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const SelectTireSizeByVehicle = (
  onComplete = (tireSize) =>
    console.warn(
      "You need to pass an onComplete function to SelectTireSizeByVehicleMobile",
      tireSize
    )
) => {
  const state = useSelectTireSizeByVehicle();

  const { component, handleSubmit } = SelectCustomTireSize(onComplete);

  return {
    component: (
      <>
        <Paper>
          <Tabs
            value={state.currentIndex}
            onChange={(_, v) => state.setCurrentIndex(v)}
          >
            {state.fitmentLabels.map(({ label }) => (
              <Tab key={label} label={label} style={{ fontSize: 12 }} />
            ))}
          </Tabs>
        </Paper>
        {!state.showCustomTireSizeComp && state.fitmentOptions?.length && (
          <Box
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: theme.palette.background.default,
              padding: 10,
            }}
          >
            <Typography
              style={{
                paddingBottom: 15,
                paddingTop: 15,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              {state.fitmentLabels[state.currentIndex].message}
            </Typography>
            {state.fitmentOptions[state.currentIndex].map((item) => {
              const { isStaggered, tireSizes } = item;
              const tireSizeComps = tireSizes.map(
                ({ rim, tireDisplaySize, setSelected }, i) => (
                  <ListItem
                    button
                    onClick={async () => {
                      const userTireSize = await setSelected();
                      onComplete(userTireSize);
                    }}
                  >
                    <ListItemIcon
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {`${rim}"`}
                    </ListItemIcon>
                    <ListItemText>
                      {isStaggered && (
                        <Typography
                          style={{ fontSize: 10, fontWeight: "bold" }}
                        >
                          {i == 0 ? "Front" : "Rear"}
                        </Typography>
                      )}
                      <Typography>{tireDisplaySize}</Typography>
                    </ListItemText>
                    <ListItemIcon>
                      <IconButton edge="end">
                        <ArrowForwardIosRoundedIcon />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                )
              );

              return (
                <Paper style={{ marginBottom: 10 }} key={item.tireFitmentKey}>
                  {tireSizeComps[0]}
                  {item.isStaggered && (
                    <>
                      <Divider />
                      {tireSizeComps[1]}
                    </>
                  )}
                </Paper>
              );
            })}
          </Box>
        )}

        {state.showCustomTireSizeComp && <>{component}</>}
      </>
    ),
    handleSubmit: state.showCustomTireSizeComp ? handleSubmit : null,
  };
};

export default SelectTireSizeByVehicle;
