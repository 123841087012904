import { useState, useEffect } from "react";
import AllTiresStore from "./all-tires-store";
import { useLocationStore } from "../location-store";
import { useTireSizeStore } from "../tire-size-store";
import TireSearchFilterStore from "./tire-search-filter-store";
import { useParams } from "react-router-dom";

const RESULTS_PER_PAGE = 3;

const defaultState = {
  currentPage: 0,
};

const useTireSearchResults = () => {
  const [state, setState] = useState(defaultState);

  const [userStore] = useLocationStore((st) => [st.userStore]);

  const [userTireSize] = useTireSizeStore((st) => [st.userTireSize]);

  const [getAllTiresStoreAsync, clearAllTiresStore] = AllTiresStore((st) => [
    st.getAllTiresStoreAsync,
    st.clearAllTiresStore,
  ]);

  const { tireSetId, sorts, filters, totalTireCount, tireSet } =
    TireSearchFilterStore();

  const { tireSizeEndPoint } = useParams();

  useEffect(() => {
    if (
      userStore &&
      userTireSize &&
      tireSizeEndPoint &&
      tireSizeEndPoint == userTireSize.tireSizeEndPoint
    ) {
      setState(defaultState);
      clearAllTiresStore();
      getAllTiresStoreAsync();
    }
  }, [userStore, userTireSize, tireSizeEndPoint]);

  useEffect(() => {
    if (tireSetId) {
      setState(defaultState);
      getNextPage();
    }
  }, [tireSetId]);

  useEffect(() => {
    return clearAllTiresStore;
  }, []);

  const getNextPage = () =>
    setState((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));

  return {
    hasNextPage: false,
    getNextPage: () => null,
    userTireSize: userTireSize ?? {},
    totalTireCount,
    tires: [],
    sorts,
    filters,
    ...(!state.currentPage
      ? {}
      : {
          hasNextPage: state.currentPage * RESULTS_PER_PAGE <= tireSet.length,
          getNextPage,
          tires: [...tireSet.slice(0, state.currentPage * RESULTS_PER_PAGE)],
        }),
  };
};

export default useTireSearchResults;
