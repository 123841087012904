import create from "zustand";
import { useEffect } from "react";
import { getLocations } from "../../services/venom-service";
import APP_ENV from "../../appEnvironment";
import dayjs from "dayjs";
import CLIENT_STORAGE from "./client/storage/client-storage";
import SessionTimerStore from "./client/session-timer-store";
import { getPromotionByLocation } from "./promotion-store";

let calendar = require("dayjs/plugin/calendar");
dayjs.extend(calendar);

let _promise;
let _sessionId;

const LocationStore = create((set, get) => {
  const initialState = {
    isInitialized: false,
    isReady: false,
    userStoreNumber: null,
    userStore: null,
    locations: [],
    fetchedCoordinates: false,
    userCoordinates: {},
  };

  const getLocationStoreAsync = async () => {
    const sessionId = SessionTimerStore.getState().sessionId;
    if (_sessionId !== sessionId) {
      _sessionId = sessionId;
      _promise = null;
      set({ isReady: false });
    }

    const _state = get();

    if (!_state.isReady && !_promise) {
      _promise = (async () => {
        let { userStoreNumber, userCoordinates } = _state;
        if (!_state.isInitialized) {
          let urlStoreNo = window.location.href
            .split("?")?.[1]
            ?.split("&")
            ?.find((i) => i.includes("store_number"))
            ?.split("=")?.[1]
            ?.slice(0, 4);

          urlStoreNo =
            !isNaN(urlStoreNo) && urlStoreNo.length === 4 ? urlStoreNo : null;

          userStoreNumber = urlStoreNo || CLIENT_STORAGE.storeNumber.getItem();

          userCoordinates = CLIENT_STORAGE.geo.getItem();
          if (!userStoreNumber && !userCoordinates) {
            userCoordinates = await getUserCoordinates();
          }
        }

        const locations = await fetchLocations({
          storeNumber: userStoreNumber,
          ...(userCoordinates ?? {}),
        });

        const userStore = locations[0];
        userStoreNumber = userStore.storeNumber;

        userStore.currentDate = dayjs(userStore.currentDateTime).format(
          "MM/DD/YYYY"
        );
        userStore.useCalendarDisplay = (incomingDate, schema = {}) =>
          dayjs(incomingDate).calendar(
            dayjs(userStore.currentDateTime),
            schema
          );

        set({
          isInitialized: true,
          isReady: true,
          userStoreNumber,
          userStore,
          locations,
        });

        CLIENT_STORAGE.storeNumber.setItem(userStoreNumber);

        _promise = null;
        return;
      })();
    }

    if (!_state.isReady) {
      await _promise;
    }

    return get();
  };

  const fetchLocations = async ({
    storeNumber = null,
    lat = null,
    lng = null,
  }) => {
    if (!lat || !lng) {
      const coordinates = await getUserCoordinates();
      if (coordinates) {
        lat = coordinates.lat;
        lng = coordinates.lng;
      }
    }

    const payLoad = Object.assign(
      {},
      ...Object.entries({ storeNumber, lat, lng })
        .filter(([_, v]) => v)
        .map(([key, val]) => ({ [key]: val }))
    );

    const data = await getLocations(payLoad);
    const locations = data.map((location) => {
      location = {
        ...location,
        promotion: getPromotionByLocation(location),
      };

      const { storeNumber } = location;
      const isSelected = () => get().userStoreNumber == storeNumber;

      return {
        ...location,
        isSelected,
        setSelected: () => {
          if (!isSelected()) {
            set({
              isReady: false,
              userStore: location,
              userStoreNumber: storeNumber,
            });
            getLocationStoreAsync();
          }
          return location;
        },
      };
    });

    return locations;
  };

  const getUserCoordinates = async () => {
    const _state = get();

    if (!_state.fetchedCoordinates) {
      const res = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${APP_ENV.googleMapsApiKey}`,
        { method: "POST" }
      );

      const { location: userCoordinates = null } = await res.json();
      set({ fetchedCoordinates: true, userCoordinates });
      CLIENT_STORAGE.geo.setItem(userCoordinates);
    }

    return get().userCoordinates;
  };

  return {
    ...initialState,
    getLocationStoreAsync,
    fetchLocations,
    getUserCoordinates,
    refreshStore: () => {
      set({ isReady: false });
      getLocationStoreAsync();
    },
  };
});

export const useLocationStore = (selector = (store) => store) => {
  const [sessionId] = SessionTimerStore((st) => [st.sessionId]);

  const [getLocationStoreAsync] = LocationStore((st) => [
    st.getLocationStoreAsync,
  ]);

  useEffect(() => {
    if (_sessionId !== sessionId) {
      getLocationStoreAsync();
    }
  }, [sessionId]);

  return LocationStore(selector);
};

export default LocationStore;
