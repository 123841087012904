import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Button,
  Hidden,
  Typography,
  Divider,
} from "@material-ui/core";
import useTireSearchResults from "../../hooks/store/tire-search-results/use-tire-search-results";
import DefaultContainer from "../../components/generic/default-container";
import StandardPageHeader from "../../components/generic/standard-page-header";
import APP_ENV from "../../appEnvironment";
import VehicleTireSizeSelectButtonWrapper from "../../components/modals/widgets/VehicleTireSizeSelectButtonWrapper";
import BreadCrumb from "../../components/generic/bread-crumb";
import InfiniteScroll from "react-infinite-scroller";
import LayoutStore from "../../hooks/store/client/layout-store";
import TireSearchFilterDrawer from "../../components/tires/tire-search-sort-filter/tire-search-filter-drawer";
import TireSearchSortOptions from "../../components/tires/tire-search-sort-filter/tire-search-sort-options";
import TireSearchFilterSection from "../../components/tires/tire-search-sort-filter/tire-search-filter-section";
import TuneIcon from "@material-ui/icons/Tune";
import useOpenTireSizeSelector from "../../hooks/util/useOpenTireSizeSelector";
import ModalStore from "../../hooks/store/modal-store";
import { useLocationStore } from "../../hooks/store/location-store";
import TireSearchResultsCard from "../../components/tires/tire-search-results-card";

const { theme } = APP_ENV;

const [Mobile, Desktop] = ["lgUp", "mdDown"].map((prop) => ({ children }) => (
  <Hidden {...{ [prop]: true }}>{children}</Hidden>
));

const TireSearchResults = () => {
  const {
    hasNextPage,
    getNextPage,
    userTireSize,
    totalTireCount,
    tires,
    sorts,
    filters,
  } = useTireSearchResults();

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const [userStore] = useLocationStore((st) => [st.userStore]);

  const [GlobalLoading] = ModalStore((st) => [st.GlobalLoading]);

  const [state, setState] = useState({
    drawerOpen: false,
  });

  useEffect(() => {
    if (!isMobile && state.drawerOpen) {
      setState((prev) => ({ ...prev, drawerOpen: false }));
    }
  }, [isMobile]);

  useEffect(() => {
    !totalTireCount ? GlobalLoading.openModal() : GlobalLoading.closeModal();
  }, [totalTireCount]);

  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: false });

  const PROMOTION = (
    <Paper
      style={{ marginBottom: 20, cursor: "pointer" }}
      elevation={8}
      onClick={openTireSizeModal}
    >
      <img
        src={userStore?.promotion?.images?.secondary ?? ""}
        style={{ width: "100%", height: "auto" }}
      />
    </Paper>
  );

  const SORT_DROP_DOWN = (
    <>
      <Typography variant="h6" style={{ paddingLeft: 10, paddingBottom: 5 }}>
        SORT BY
      </Typography>
      <TireSearchSortOptions sorts={sorts} />
    </>
  );

  const TIRE_COLLECTION = (
    <InfiniteScroll loadMore={getNextPage} hasMore={hasNextPage}>
      {tires.map((tire, i) => (
        <TireSearchResultsCard key={i} tire={tire} />
      ))}
    </InfiniteScroll>
  );

  const FILTERS = (
    <>
      {filters.map((filter) => (
        <TireSearchFilterSection {...filter} key={filter.label} />
      ))}
    </>
  );

  const CHANGE_TIRE_SIZE = (
    <VehicleTireSizeSelectButtonWrapper>
      <Button color={"primary"} variant={"contained"} style={{ height: 35 }}>
        Change Tire Size
      </Button>
    </VehicleTireSizeSelectButtonWrapper>
  );

  const { tireDisplaySize = "", tireSizeEndPoint = "" } = userTireSize;

  return (
    <>
      <BreadCrumb paths={[["Tires", "/tires"], [tireSizeEndPoint]]} />
      <StandardPageHeader
        title={`Shop Tires - ${tireDisplaySize}`}
        subtitle={totalTireCount ? `${totalTireCount} total items` : ""}
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Grid
          container
          style={{
            padding: 10,
            display: "flex",
            justifyContent: "center",
            maxWidth: 1500,
          }}
        >
          <Mobile>
            <Box
              style={{
                width: "100%",
                maxWidth: 750,
              }}
            >
              {PROMOTION}
              <Box style={{ width: "100%", maxWidth: 250 }}>
                {SORT_DROP_DOWN}
              </Box>
              <Divider />
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  style={{ maxWidth: 350, height: 50 }}
                  startIcon={<TuneIcon />}
                  onClick={() =>
                    setState((prev) => ({ ...prev, drawerOpen: true }))
                  }
                >
                  Filter
                </Button>
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 20,
                }}
              >
                {CHANGE_TIRE_SIZE}
              </Box>

              {TIRE_COLLECTION}
              <TireSearchFilterDrawer
                isOpen={state.drawerOpen}
                closeModal={() =>
                  setState((prev) => ({ ...prev, drawerOpen: false }))
                }
              >
                {FILTERS}
              </TireSearchFilterDrawer>
            </Box>
          </Mobile>
          <Desktop>
            <Grid
              item
              xs={4}
              style={{ padding: 10, display: "flex", justifyContent: "center" }}
            >
              <Box
                style={{
                  width: "100%",
                }}
              >
                {SORT_DROP_DOWN}
                <Typography
                  variant="h6"
                  style={{ paddingLeft: 10, paddingBottom: 5 }}
                >
                  FILTER BY
                </Typography>
                {FILTERS}
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              style={{ padding: 10, display: "flex", justifyContent: "center" }}
            >
              <Box
                style={{
                  width: "100%",
                }}
              >
                {PROMOTION}
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: 20,
                  }}
                >
                  {CHANGE_TIRE_SIZE}
                </Box>

                {TIRE_COLLECTION}
              </Box>
            </Grid>
          </Desktop>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default TireSearchResults;
