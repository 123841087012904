import { Paper, Box, Grid, Button, Typography } from "@material-ui/core";
import DefaultContainer from "../../components/generic/default-container";
import StandardPageHeader from "../../components/generic/standard-page-header";
import APP_ENV from "../../appEnvironment";
import QuickAppointmentCartReview from "../../components/quick-appointment/quick-appointment-cart-review";
import QuickAppointmentAppointmentSelection from "../../components/quick-appointment/quick-appointment-appointment-selection";
import QuickAppointmentUserInfo from "../../components/quick-appointment/quick-appointment-user-info";
import useZState from "../../hooks/util/useZState";
import ExpandIcons from "../../components/generic/expand-icons";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import QuickSelectServicesCollection from "../../components/quick-appointment/cart-review/quick-select-services-collection";
import { useCartStore } from "../../hooks/store/cart-store";
import { useAppointmentStore } from "../../hooks/store/appointment-store";
import { useEffect } from "react";
import LayoutStore from "../../hooks/store/client/layout-store";
import StyledInfoCard from "../../components/quick-appointment/widgets/styled-info-card";
import PrimaryActionSelectButton from "../../components/quick-appointment/widgets/primary-action-select-button";
import { useRetailOrderSubmit } from "../../hooks/useRetailOrder";
import MetaTags from "../../components/reusable/ReactMetaTags";

const { theme } = APP_ENV;

const QuickAppointmentMain = () => {
  const [getCartStoreAsync, cartItems] = useCartStore((st) => [
    st.getCartStoreAsync,
    st.cartItems,
  ]);

  const [getAppointmentStoreAsync, userAppointment] = useAppointmentStore(
    (st) => [st.getAppointmentStoreAsync, st.userAppointment]
  );

  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const hasSomethingInCart = cartItems.length;

  const [getState, setState] = useZState({
    isReady: false,
    viewIndex: 0,
  });

  useEffect(() => {
    (async () => {
      const { cartItems } = await getCartStoreAsync();
      const { userAppointment } = await getAppointmentStoreAsync();
      const { viewIndex, isReady } = getState();

      let newIndex = viewIndex ?? 0;

      switch (true) {
        case !cartItems.length:
          newIndex = 1;
          break;
        case !userAppointment && viewIndex == 3 && isReady:
          newIndex = 2;
          break;
      }

      setState({
        isReady: true,
        viewIndex: newIndex,
      });
    })();
  }, [userAppointment, hasSomethingInCart]);

  const setViewIndex = (viewIndex) => () => setState({ viewIndex });

  const state = getState();

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 200);
    }
  }, [state.viewIndex]);

  const handleRetailOrderSubmit = useRetailOrderSubmit();

  const onSubmitAppointmentClick = async () => {
    const { success = false, onSuccess = () => null } =
      await handleRetailOrderSubmit();

    if (success) {
      onSuccess();
    }
  };

  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} provides same day appointments and fast service. 
        Oil Change includes free tire rotation, free underhood inspection, and
        free brake inspection. Schedule your appointment now!`}
        canonical="/oilrecommendations"
        title={`Schedule Appointment | ${APP_ENV.appBrandName}`}
      />

      <StandardPageHeader
        title="Quick Appointment"
        subtitle="You are just a few steps away from scheduling your online appointment."
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: theme.palette.background.default,
            paddingTop: 20,
            paddingBottom: 40,
          }}
        >
          <Box style={{ width: "100%", maxWidth: theme.breakpoints.values.md }}>
            {[
              {
                key: 0,
                title: "Cart",
                isVisible: hasSomethingInCart,
                isDisabled: false,
                showExpandedButton: true,
                onClose: setViewIndex(!userAppointment ? 2 : 3),
                closedComp: (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: 8,
                    }}
                  >
                    <CheckCircleRoundedIcon
                      style={{
                        color: theme.palette.success.main,
                        marginRight: 5,
                      }}
                    />
                    <Typography style={{ fontSize: 14 }}>
                      <strong>{cartItems.length}</strong>
                      {` ${cartItems.length == 1 ? "Item" : "items"} In Cart`}
                    </Typography>
                  </Box>
                ),
                openComp: (
                  <QuickAppointmentCartReview
                    onComplete={setViewIndex(!userAppointment ? 2 : 3)}
                  />
                ),
              },
              {
                key: 1,
                title: "What services do you need?",
                isVisible: !hasSomethingInCart,
                isDisabled: false,
                showExpandedButton: false,
                onClose: setViewIndex(2),
                closedComp: <></>,
                openComp: (
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 25,
                    }}
                  >
                    <Grid item xs={12} sm={12} md={8}>
                      <StyledInfoCard>
                        <QuickSelectServicesCollection />
                      </StyledInfoCard>
                      <PrimaryActionSelectButton
                        buttonTitle={"Save & Continue"}
                        onClick={setViewIndex(2)}
                        isDisabled={!hasSomethingInCart}
                      />
                    </Grid>
                  </Grid>
                ),
              },
              {
                key: 2,
                title: "Appointment",
                isVisible: hasSomethingInCart,
                isDisabled: false,
                showExpandedButton: true,
                onClose: setViewIndex(0),
                closedComp: userAppointment ? (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: 8,
                    }}
                  >
                    <CheckCircleRoundedIcon
                      style={{
                        color: theme.palette.success.main,
                        marginRight: 5,
                      }}
                    />
                    <Typography style={{ fontSize: 13 }}>
                      <strong>
                        {userAppointment.isWaiting ? "Waiting" : "Drop-off"}
                      </strong>
                      {" | "}
                      {userAppointment.apptDateTimeDisplay}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                ),
                openComp: (
                  <QuickAppointmentAppointmentSelection
                    onComplete={setViewIndex(3)}
                  />
                ),
              },
              {
                key: 3,
                title: "Order",
                isVisible: hasSomethingInCart,
                isDisabled: !userAppointment,
                showExpandedButton: true,
                onClose: setViewIndex(2),
                closedComp: <></>,
                openComp: (
                  <QuickAppointmentUserInfo
                    onComplete={onSubmitAppointmentClick}
                    onEditCartClick={setViewIndex(0)}
                  />
                ),
              },
            ]
              .filter(
                ({ isVisible, key }) => isVisible || state.viewIndex == key
              )
              .map((item) => {
                const isExpanded = state.viewIndex == item.key;

                return (
                  <Paper
                    id={`_${item.key}`}
                    key={item.key}
                    style={{
                      padding: 15,
                      borderRadius: 12,
                      marginBottom: 10,
                      border: `${isExpanded ? 1 : 0}px solid ${
                        theme.palette.primary.main
                      }`,
                      width: "100%",
                    }}
                    elevation={isExpanded ? 5 : 2}
                  >
                    <Box
                      style={{ width: "100%", display: "block" }}
                      disabled={item.isDisabled}
                      component={Button}
                      onClick={
                        isExpanded ? item.onClose : setViewIndex(item.key)
                      }
                    >
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h4">{item.title}</Typography>
                        {item.showExpandedButton && !item.isDisabled && (
                          <>
                            <ExpandIcons
                              isExpanded={isExpanded}
                              fontSize={22}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid container>{!isExpanded && item.closedComp}</Grid>
                    </Box>
                    <Grid container>{isExpanded && item.openComp}</Grid>
                  </Paper>
                );
              })}
          </Box>
        </Box>
      </DefaultContainer>
    </>
  );
};

export default QuickAppointmentMain;
