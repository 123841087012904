import create from "zustand";
import TireSizeStore from "../tire-size-store";
import TireItemsStore from "../tire-items-store";
import TireSearchFilterStore from "./tire-search-filter-store";

let _promise;
let _tireSizeEndPoint;

const AllTiresStore = create((set, get) => {
  const initialState = {
    isReady: false,
    totalTireCount: 0,
    allTires: [],
  };

  const getAllTiresStoreAsync = async () => {
    const { tireSizeEndPoint } = TireSizeStore.getState().userTireSize;

    if (_tireSizeEndPoint !== tireSizeEndPoint) {
      _tireSizeEndPoint = tireSizeEndPoint;
      _promise = null;
      set({ isReady: false });
    }

    const _state = get();

    if (!_state.isReady && !_promise) {
      _promise = (async () => {
        const allTires = await TireItemsStore.getState().getActiveTireItems({
          tireSizeEndPoint,
        });
        set({ isReady: true, totalTireCount: allTires.length, allTires });
        TireSearchFilterStore.getState().initializeTireFilters(allTires);
        _promise = null;
      })();
    }

    if (!_state.isReady) {
      await _promise;
    }

    return get();
  };

  return {
    ...initialState,
    getAllTiresStoreAsync,
    clearAllTiresStore: () => {
      set(initialState);
      TireSearchFilterStore.getState().initializeTireFilters();
    },
  };
});

export default AllTiresStore;
