import { useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  Collapse,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";

const SemiCollapseableDescription = ({ description = "" }) => {
  const [isExpaned, setIsExpanded] = useState(false);

  return (
    <>
      {Boolean(description) && (
        <>
          <Collapse in={isExpaned} collapsedSize={50}>
            <Typography>{description}</Typography>
          </Collapse>
          <Box
            style={{
              display: "flex",
              width: "100%",
              paddingRight: 20,
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            <Button
              size={"small"}
              onClick={() => setIsExpanded((prev) => !prev)}
              endIcon={
                isExpaned ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )
              }
            >
              Read more
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default SemiCollapseableDescription;
