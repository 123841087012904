import VyperLandingPage from "./template/vyper-landing-page";
import APP_ENV from "../../../appEnvironment";
import { STATIC_CART_ITEMS } from "../../../static/cart-items";
import dayjs from "dayjs";

export const VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE = {
  promotionDirectory: "/50-percent-off-oil-change",
  edgeItemId: 97764,
  activePromotions: [
    {
      endPoint: "california",
      stores: [1009, 1015, 1052],
    },
    {
      endPoint: "arizona",
      stores: [
        3002, 3006, 3014, 3018, 3019, 3020, 3027, 3032, 3033, 3035, 3037,
      ],
    },
    {
      endPoint: "denver",
      stores: [2007, 2008, 2009, 2010, 2011, 2012, 2013],
    },
    {
      endPoint: "california-2024",
      stores: [1102, 1103, 1147, 1175, 1185, 1187],
    },
    {
      endPoint: "reno-nv",
      stores: [3200, 3201, 3202],
      story: `We are excited to celebrate the Reno community. A 50% off oil change is our present to you!`,
    },
  ].map(({ endPoint, stores, story = "" }) => ({
    endPoint,
    stores: stores.map((val) => String(val)),
    story: story
      ? story
      : `We are excited to celebrate our communities. A 50% off oil change is our present to you!`,
  })),
};

const VyperFiftyPercentOffOilChange = () => {
  const PROMO_IMAGES = {
    1: "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/bbt_50-percent-off-oil-change.png",
    3: "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/atd_50-percent-off-oil-change.png",
  };

  return (
    <VyperLandingPage
      lineOneText="Save 50% on your"
      lineTwoText="Oil Change Now"
      lineThreeText="*Includes Tire Rotation & Vehicle Inspection"
      buttonText="Get 50% Off NOW"
      promotionImage={PROMO_IMAGES[APP_ENV.appBrandId]}
      promotionCartItem={STATIC_CART_ITEMS.FIFTY_PERCENT_OFF_OIL_CHANGE}
      activePromotions={VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE.activePromotions}
    />
  );
};

export default VyperFiftyPercentOffOilChange;
