import { roundMoney } from "../helpers/generic";
import SameDayAppointmentEmail from "../components/order-confirmation/same-day-appointment-email";
import APP_ENV from "../appEnvironment";
import {
  getInStockAlternativeTireOptions,
  getOrderRetail,
} from "./venom-service";
import { EDGE_ITEM_IDS } from "../static/cart-items";
import dayjs from "dayjs";
import { renderToString } from "react-dom/server";
import { sendMailChimpEmail } from "./venom-service";
import RetailOrderConfirmationTemplate from "../components/order-confirmation/retail-order-confirmation-template";

const sendRetailOrderConfirmationEmail = async ({ orderGUID }) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const emails = [];

  const order = await getOrderRetail({ orderGUID });

  if (!order) return null;

  const html = renderToString(
    <RetailOrderConfirmationTemplate {...order} isForEmail={true} />
  );

  const customerConfirmationEmail = {
    html,
    subject: "Your Car Appointment Confirmation",
    fromName: APP_ENV.appBrandName,
    to: [
      {
        email: order.customerEmail,
        name: order.customerEmail,
      },
    ],
  };

  emails.push(customerConfirmationEmail);

  const internalTrackingEmail = {
    html,
    subject: `Appt - ${APP_ENV.appBrandName} - $${roundMoney(
      order.totalPrice
    ).toFixed(2)}`,
    fromName: "Web Order Tracking",
    to: [
      {
        email: "onlineappttracking@bigbrandtire.com",
        name: "Online Appt Tracking",
      },
    ],
  };

  emails.push(internalTrackingEmail);

  const sameDayEmail = dayjs().isSame(order.appointmentDateTime, "day")
    ? async () => {
        const { storeNumber, retailOrderGUID } = order;
        const alternativeTires = order.subtotal
          ? await getInStockAlternativeTireOptions(retailOrderGUID)
          : [];
        return {
          html: renderToString(
            <div>
              {alternativeTires.length && (
                <SameDayAppointmentEmail alternativeTires={alternativeTires} />
              )}
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          ),
          subject: order.subtotal
            ? `NEW Same-Day Tire Appt - Store ${storeNumber}`
            : `NEW Same-Day Web Appt - Store ${storeNumber}`,
          fromName: "Support Center",
          to: [
            {
              name: "Store Manager",
              email: `${
                APP_ENV.appBrandId !== 3
                  ? `${storeNumber}mgr`
                  : `${storeNumber}mgr.atd`
              }@bigbrandtire.com`,
            },
            {
              name: "Store",
              email: `${storeNumber}store@bigbrandtire.com`,
            },
          ],
        };
      }
    : null;

  emails.push(sameDayEmail ? await sameDayEmail() : null);

  const servicePartnershipNotifcationEmail = [
    order.cartItems.find(({ itemId }) =>
      [EDGE_ITEM_IDS.silverRockAppt, EDGE_ITEM_IDS.shieldNetAppt].includes(
        itemId
      )
    ),
  ]
    .filter((i) => i)
    .map(({ itemId }) => ({
      html,
      subject: `${
        {
          [EDGE_ITEM_IDS.silverRockAppt]: "SilverRock",
          [EDGE_ITEM_IDS.shieldNetAppt]: "Shield Repair Network",
        }?.[itemId] || "Service Partnership"
      } Appt - ${APP_ENV.appBrandName} - $${roundMoney(
        order.totalPrice
      ).toFixed(2)}`,
      fromName: "Web Order Tracking",
      to: [
        {
          email: "ken.mcqueen@bigbrandtire.com",
          name: "Ken",
        },
      ],
    }))?.[0];

  emails.push(servicePartnershipNotifcationEmail);

  const responses = await Promise.allSettled(
    emails.filter((e) => e).map((email) => sendMailChimpEmail(email))
  );
};

export default sendRetailOrderConfirmationEmail;
