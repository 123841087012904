import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Button,
  Collapse,
  InputAdornment,
} from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";
import StyledInfoCard from "../widgets/styled-info-card";
import SelectVehicle from "../../reusable/conditional-selection/SelectVehicle";
import ExpandIcons from "../../generic/expand-icons";

const { theme } = APP_ENV;

const formatPhoneNumber = (unformatedNumber) => {
  const phoneNumberLength = unformatedNumber?.length ?? 0;

  if (phoneNumberLength <= 3) {
    return unformatedNumber;
  }

  if (phoneNumberLength < 7) {
    return `(${unformatedNumber.slice(0, 3)}) ${unformatedNumber.slice(3)}`;
  }

  return `(${unformatedNumber.slice(0, 3)}) ${unformatedNumber.slice(
    3,
    6
  )}-${unformatedNumber.slice(6, 10)}`;
};

const unformatPhoneNumber = (formattedPhoneNumber) =>
  formattedPhoneNumber
    ? [...(formattedPhoneNumber ?? [])]
        .filter((char) => char == "0" || parseInt(char))
        .filter((_, i) => i < 10)
        .join("") ?? ""
    : "";

const CollectUserInfo = ({
  userName,
  userPhoneNumber,
  userEmail,
  vehicleDisplayName,
  userApptNotes,
}) => {
  const [isVehicleOpen, setIsVehicleOpen] = useState(false);
  const [isNoteOpen, setIsNoteOpen] = useState(false);

  useEffect(() => {
    if (!isNoteOpen && userApptNotes.value.length) {
      setIsNoteOpen(true);
    }
  }, [userApptNotes.value]);

  const { component, handleSubmit } = SelectVehicle(() =>
    setIsVehicleOpen(false)
  );

  return (
    <Grid container>
      {[
        ["First & Last Name", userName],
        [
          "Phone Number",
          {
            ...userPhoneNumber,
            value: formatPhoneNumber(userPhoneNumber.value),
            handleChange: (e) => {
              e.target.value = unformatPhoneNumber(e.target.value);
              userPhoneNumber.handleChange(e);
            },
          },
          ,
        ],
        ["Email", userEmail],
      ].map(([label, field], i) => {
        const { key, value, errorMessage, handleChange } = field;
        return (
          <TextField
            key={key}
            id={key}
            name={key}
            label={label}
            fullWidth
            value={value}
            onChange={handleChange}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            color="primary"
            variant="outlined"
            style={{ paddingBottom: 20 }}
            InputProps={{
              style: {
                borderRadius: 12,
                backgroundColor: "white",
              },
            }}
          />
        );
      })}

      <TextField
        key={vehicleDisplayName.key}
        id={vehicleDisplayName.key}
        name={vehicleDisplayName.key}
        label={"Vehicle"}
        fullWidth
        value={vehicleDisplayName.value}
        type="button"
        onClick={() => setIsVehicleOpen(!isVehicleOpen)}
        error={Boolean(vehicleDisplayName.errorMessage)}
        helperText={vehicleDisplayName.errorMessage}
        color="primary"
        variant="outlined"
        style={{ marginBottom: 20 }}
        inputProps={{ style: { textAlign: "left" } }}
        InputProps={{
          style: {
            borderRadius: 12,
            backgroundColor: "white",
            cursor: "pointer",
          },
          endAdornment: (
            <InputAdornment position="end">
              <ExpandIcons isExpanded={isVehicleOpen} />
            </InputAdornment>
          ),
        }}
      />
      <Collapse in={isVehicleOpen}>
        <Box style={{ marginBottom: 20 }}>
          <StyledInfoCard isGrey={false}>
            {component}
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleSubmit}
                fullWidth
                style={{
                  maxWidth: 350,
                  marginTop: 20,
                  marginBottom: 20,
                  height: 50,
                }}
                variant="contained"
                color="secondary"
              >
                Save Vehicle
              </Button>
            </Box>
          </StyledInfoCard>
        </Box>
      </Collapse>

      <Box style={{ width: "100%" }}>
        {!Boolean(userApptNotes.value.length) && (
          <Button
            startIcon={<ExpandIcons isExpanded={isNoteOpen} />}
            color="primary"
            variant="outlined"
            onClick={() => setIsNoteOpen(!isNoteOpen)}
          >
            {isNoteOpen ? "Close" : "Add Note"}
          </Button>
        )}

        <Collapse in={isNoteOpen}>
          <TextField
            fullWidth
            id="customerNotes"
            name="customerNotes"
            label="Appointment Notes"
            value={userApptNotes.value}
            onChange={userApptNotes.handleChange}
            error={Boolean(userApptNotes.errorMessage)}
            helperText={userApptNotes.errorMessage}
            multiline
            minRows={3}
            variant="outlined"
            color="secondary"
            style={{ marginTop: 10, marginBottom: 10 }}
            InputProps={{
              style: {
                borderRadius: 12,
                backgroundColor: "white",
              },
            }}
          />
        </Collapse>
      </Box>
    </Grid>
  );
};

export default CollectUserInfo;
