import create from "zustand";
import GlobalLoading from "../../components/modals/GlobalLoading";
import LocationSelectDrawer from "../../components/modals/location-select-drawer";
import VehicleTireSizeSelectionDrawerMobile from "../../components/modals/VehicleTireSizeSelectionDrawerMobile";
import VehicleTireSizeSelectionModalDesktop from "../../components/modals/VehicleTireSizeSelectionModalDesktop";
import SelectVehicleOnlyModalDesktop from "../../components/modals/SelectVehicleOnlyModalDesktop";
import CartDrawer from "../../components/modals/cart-drawer";
import TireRecyclingModal from "../../components/modals/tire-recycling-modal";
import LifetimeTireMaintCertModal from "../../components/modals/lifetime-tire-maint-cert-modal";
import OlarkChat from "./client/olark-chat";

const MODALS = [
  ["GlobalLoading", GlobalLoading],
  ["LocationSelectDrawer", LocationSelectDrawer],
  [
    "VehicleTireSizeSelectionDrawerMobile",
    VehicleTireSizeSelectionDrawerMobile,
  ],
  [
    "VehicleTireSizeSelectionModalDesktop",
    VehicleTireSizeSelectionModalDesktop,
  ],
  ["SelectVehicleOnlyModalDesktop", SelectVehicleOnlyModalDesktop],
  ["CartDrawer", CartDrawer],
  ["TireRecyclingModal", TireRecyclingModal],
  ["LifetimeTireMaintCertModal", LifetimeTireMaintCertModal],
];

const ModalStore = create((set, get) => {
  const createModalActions = (displayName) => {
    const modalAction = (bool) => {
      return (props = {}) => {
        if (!bool) {
          props = {};
          OlarkChat.shrink();
        }

        if (bool) {
          OlarkChat.hide();
        }

        set((prev) => {
          return {
            ...prev,
            [displayName]: {
              ...prev[displayName],
              isOpen: bool,
              props,
            },
          };
        });
      };
    };

    return {
      openModal: modalAction(true),
      closeModal: modalAction(false),
      toggleModal: () => {
        const current = !get()[displayName].isOpen;
        modalAction(current)();
      },
      props: {},
    };
  };

  const closeAllModals = () => {
    const modals = get();
    Object.values(modals).forEach(
      ({ closeModal }) => closeModal && closeModal()
    );
  };

  return Object.assign(
    { closeAllModals },
    ...MODALS.map(([displayName]) => {
      return {
        [displayName]: {
          isOpen: false,
          ...createModalActions(displayName),
        },
      };
    })
  );
});

export const ModalController = () => {
  const modalStore = ModalStore((st) => st);

  return (
    <>
      {MODALS.map(([displayName, Component]) => {
        return <Component key={displayName} {...modalStore[displayName]} />;
      })}
    </>
  );
};

export default ModalStore;
