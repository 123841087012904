import APP_ENV from "../../appEnvironment";
import StarIcon from "@material-ui/icons/Star";
/*

*/

const getServiceTileImage = (appBrandId) => {
  switch (appBrandId) {
    case 1:
      return "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/bfg-labor-day-hero-image-2024.jpg";
    case 2:
      return "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/bfg-labor-day-hero-image-2024.jpg";
    case 3:
      return "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/shelll-coupon-2.jpg";
  }
};

const getGenericImages = (appBrandId) => {
  switch (appBrandId) {
    case 1:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_nexen-only_hero_labor-day-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_tire-look-up_nexen_labor-day-2024.jpg",
      };
    case 2:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_nexen-only_hero_labor-day-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_tire-look-up_nexen_labor-day-2024.jpg",
      };
    case 3:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_nexen-only_hero_labor-day-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_tire-look-up_nexen_labor-day-2024.jpg",
      };
  }
};

const getColoradoImages = (appBrandId) => {
  switch (appBrandId) {
    case 1:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_hero_labor-day-sale-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_tire-look-up_sum-nex_labor-day-2024.jpg",
      };
    case 2:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_hero_labor-day-sale-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_tire-look-up_sum-nex_labor-day-2024.jpg",
      };
    case 3:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_hero_labor-day-sale-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_tire-look-up_sum-nex_labor-day-2024.jpg",
      };
  }
};

const getFocusImages = (appBrandId) => {
  switch (appBrandId) {
    case 1:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_hero_labor-day-sale-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_tire-look-up_labor-day-2024.jpg",
      };
    case 2:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_hero_labor-day-sale-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_tire-look-up_labor-day-2024.jpg",
      };
    case 3:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_hero_labor-day-sale-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_tire-look-up_labor-day-2024.jpg",
      };
  }
};

export const PROMO_THEME = {
  sitePromoName: "Labor Day Sale",
  sitePromoColors: {
    primary: "#DD0000",
    secondary: "#374089",
    tertiary: "#DD0000",
  },
  SitePromoIcon: StarIcon,
  serviceTileImage: getServiceTileImage(APP_ENV.appBrandId),
};

const PROMO_OPTIONS = {
  generic: {
    promoType: "gen_b3g1",
    brandEndPoints: ["nexen"],
    edgeItemId: 104104,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 3, Get 1 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 3, Get 1 FREE!*",
    },
    images: getGenericImages(APP_ENV.appBrandId),
  },
  colorado: {
    promoType: "col_b3g1",
    brandEndPoints: ["summit", "nexen"],
    edgeItemId: 103000,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 3, Get 1 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 3, Get 1 FREE!*",
    },
    images: getColoradoImages(APP_ENV.appBrandId),
  },
  focus: {
    promoType: "focus_b2g2",
    brandEndPoints: ["summit", "nexen"],
    edgeItemId: 103820,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 2, Get 2 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 2, Get 2 FREE!*",
    },
    images: getFocusImages(APP_ENV.appBrandId),
  },
};

const PROMO_STORE_GROUPS = {
  tucson: [
    "3020",
    "3025",
    "3026",
    "3027",
    "3028",
    "3029",
    "3030",
    "3031",
    "3032",
    "3033",
    "3034",
    "3035",
  ],
  otherAz: [
    "3001",
    "3002",
    "3003",
    "3004",
    "3005",
    "3006",
    "3007",
    "3008",
    "3009",
    "3010",
    "3011",
    "3012",
    "3013",
    "3014",
    "3015",
    "3016",
    "3017",
    "3018",
    "3019",
    "3021",
    "3022",
    "3023",
    "3024",
    "3036",
    "3037",
  ],
  vegas: [
    "3203",
    "3204",
    "3205",
    "3206",
    "3207",
    "3208",
    "3209",
    "3210",
    "3211",
    "3212",
    "3213",
    "3214",
  ],
  denver: ["2007", "2008", "2009", "2010", "2011", "2012", "2013"],
  reno: ["3200", "3201", "3202"],
};

export const getPromotionByLocation = ({ storeNumber = null }) => {
  let promotion = PROMO_OPTIONS.generic;

  if (APP_ENV.appBrandId == 2) {
    promotion = PROMO_OPTIONS.colorado;
  }

  if (
    [
      ...PROMO_STORE_GROUPS.tucson,
      ...PROMO_STORE_GROUPS.otherAz,
      ...PROMO_STORE_GROUPS.vegas,
      ...PROMO_STORE_GROUPS.denver,
      ...PROMO_STORE_GROUPS.reno,
    ].includes(storeNumber)
  ) {
    promotion = PROMO_OPTIONS.focus;
  }

  return {
    ...PROMO_THEME,
    ...promotion,
  };
};
