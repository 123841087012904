import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "@material-ui/core";
import { CAR_SERVICES_ROUTE_DIRECTORY as carServiceDirectory } from "./static/cart-items";
import PrivateRoute from "./components/reusable/PrivateRoute";
import MainLayout from "./components/layout/main-layout";
import { ModalController } from "./hooks/store/modal-store";

import Home from "./views/home";
import TestPage from "./views/TestPage";

//
import TiresMain from "./views/tires/tires-main";
import TiresModelsByBrand from "./views/tires/tires-models-by-brand";
import TiresByBrandModel from "./views/tires/tires-by-brand-model";
import TireItemDetails from "./views/tires/tire-item-details";
import TireSearchResults from "./views/tires/tire-search-results";
import TireItemReview from "./views/tires/tire-item-review";

//
import EvTiresMain from "./views/ev-tires/ev-tires-main.js";

//
import WheelsMain from "./views/wheels/wheels-main";
//
import OilChangeMain from "./views/oil-change/oil-change-main";
//
import CarServicesMain from "./views/car-services/car-services-main";
import CarServiceSingle from "./views/car-services/car-services-single";
//
import LocationsMain from "./views/locations/locations-main";
import LocationsSingle from "./views/locations/locations-single";
import LocationsNearby from "./views/locations/locations-nearby";
import NewStoreLandingPage from "./views/locations/new-store-landing-page";
//
import CouponsMain from "./views/coupons/coupons-main";
//
import QuickAppointmentMain from "./views/quick-appointment/quick-appointment-main";
//
import AboutUs from "./views/about-us";
import ContactUs from "./views/contact-us";
import TermsOfUse from "./views/terms-of-use";
import PrivacyPolicy from "./views/privacy-policy";
import ShippingPolicy from "./views/shipping-policy";
import TireWarranty from "./views/tire-warranty";
import FleetServices from "./views/fleet-services";

//
import { CREDIT_PAGE_ENDPOINT } from "./hooks/store/credit-store.js";
import SyncronyCredit from "./views/credit/syncrony-credit.js";

//
import Careers from "./views/careers";
//
//
import OrderConfirmationRetail from "./views/order-confirmation/order-confirmation-retail";
//
import WholesaleLogin from "./views/WholesaleLogin";
import WholeSale from "./views/WholeSale.jsx";
//

import VyperFreeOilChange, {
  VYPER_FREE_OIL_CHANGE,
} from "./components/promotions/vyper/vyper-free-oil-change";

import VyperFiftyPercentOffOilChange, {
  VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE,
} from "./components/promotions/vyper/vyper-fifty-percent-off-oil-change";

import VyperFiftyPercentOffOilChangeKroq, {
  VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ,
} from "./components/promotions/vyper/vyper-fifty-percent-off-oil-change-kroq.js";

import Vyper25OffAC, {
  VYPER_25_OFF_AC,
} from "./components/promotions/vyper/vyper-25-off-ac.js";

import Vyper25OffAcIceCream, {
  VYPER_AC_25_OFF_ICE_CREAM,
} from "./components/promotions/vyper/vyper-25-off-ac-with-ice-cream.js";

//

import SilverRockServicePartnership from "./views/service-partnerships/sliver-rock-service-partnership.js";

//

//

import SaveHundredBrakes from "./views/coupons/save-hundred-brakes.js";

//

import LocationsDirectory from "./views/locations/locations-directory";
import EasySurveyMain from "./views/easy-survey/easy-survey-main";
import SmogCheck from "./views/smog-check";
import SMOG_CHECK_CONFIG from "./static/smog-check-config";
import VyperEssentialPersonnelDiscount, {
  VYPER_ESSENTIAL_PERSONNEL_DISCOUNT,
} from "./components/promotions/vyper/vyper-essential-personnel-discount";
import VipMembership from "./views/vip-membership";
import QuickLink from "./views/quick-link";
import ShieldRepairNetworkServicePartnership from "./views/service-partnerships/shield-repair-network-service-partnership.js";

export const TIRES_ROUTE_DIRECTORY = "/tires";

const tireBrand = `${TIRES_ROUTE_DIRECTORY}/:brandEndPoint`;
const tireBrandModel = `${tireBrand}/:itemModelEndPoint`;
const tireBrandModelSize = `${tireBrandModel}/:tireSizeEndPoint`;
const tireBrandModelSizePartNumber = `${tireBrandModelSize}/:partNumberEndPoint`;

export const QUICK_APPOINTMENT_DIRECTORY = "/car-appointment";
export const RETAIL_ORDER_CONFIRMATION_DIRECTORY = "/order-confirmation";
export const CAR_SERVICES_ROUTE_DIRECTORY = carServiceDirectory;

const Routes = () => {
  return (
    <Container
      style={{
        width: "100%",
        maxWidth: "100vw",
        padding: 0,
        margin: 0,
      }}
    >
      <Switch>
        <Route
          exact
          path="/easy-survey/:thumbType"
          component={EasySurveyMain}
        />
        <MainLayout>
          <Route exact path={"/"} component={Home} />
          <Route exact path={TIRES_ROUTE_DIRECTORY} component={TiresMain} />
          <Route exact path={tireBrand} component={TiresModelsByBrand} />
          <Route
            exact
            path={[tireBrandModel, tireBrandModelSize]}
            component={TiresByBrandModel}
          />
          <Route
            exact
            path={tireBrandModelSizePartNumber}
            component={TireItemDetails}
          />
          <Route
            exact
            path={`/search/:pageNumber/:tireSizeEndPoint`}
            component={TireSearchResults}
          />
          <Route
            exact
            path={`/tire-review/:brandEndPoint/:itemModelEndPoint`}
            component={TireItemReview}
          />

          <Route exact path="/electric-vehicle-tires" component={EvTiresMain} />

          <Route path="/wheels" component={WheelsMain} />
          <Route path="/oilrecommendations" component={OilChangeMain} />
          <Route
            exact
            path={CAR_SERVICES_ROUTE_DIRECTORY}
            component={CarServicesMain}
          />
          <Route
            exact
            path={`${CAR_SERVICES_ROUTE_DIRECTORY}/:serviceEndPoint`}
            component={CarServiceSingle}
          />

          <Route exact path="/locations" component={LocationsMain} />
          <Route exact path="/locations/:pageId" component={LocationsSingle} />
          <Route
            exact
            path="/locations-directory"
            component={LocationsDirectory}
          />
          <Route
            exact
            path="/tire-shop/:displayCityEndPoint"
            component={LocationsNearby}
          />

          <Route path="/new-locations/:slug" component={NewStoreLandingPage} />

          <Route
            exact
            path={`${VYPER_FREE_OIL_CHANGE.promotionDirectory}/:promotionEndPoint`}
            component={VyperFreeOilChange}
          />

          <Route
            exact
            path={`${VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE.promotionDirectory}/:promotionEndPoint`}
            component={VyperFiftyPercentOffOilChange}
          />

          <Route
            exact
            path={`${VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE_KROQ.promotionDirectory}`}
            component={VyperFiftyPercentOffOilChangeKroq}
          />

          <Route
            exact
            path={`${VYPER_ESSENTIAL_PERSONNEL_DISCOUNT.promotionDirectory}/:promotionEndPoint`}
            component={VyperEssentialPersonnelDiscount}
          />

          <Route
            exact
            path={`${VYPER_25_OFF_AC.promotionDirectory}/:promotionEndPoint`}
            component={Vyper25OffAC}
          />
          <Route
            exact
            path={`${VYPER_AC_25_OFF_ICE_CREAM.promotionDirectory}/:promotionEndPoint`}
            component={Vyper25OffAcIceCream}
          />

          <Route exact path="/coupons" component={CouponsMain} />

          <Route
            exact
            path="/save-hundred-brakes"
            component={SaveHundredBrakes}
          />

          <Route
            path={QUICK_APPOINTMENT_DIRECTORY}
            component={QuickAppointmentMain}
          />

          <Route
            exact
            path={RETAIL_ORDER_CONFIRMATION_DIRECTORY}
            component={OrderConfirmationRetail}
          />

          <Route
            exact
            path={"/service-partnerships/silver-rock"}
            component={SilverRockServicePartnership}
          />

          <Route
            exact
            path={"/service-partnerships/shield-repair-network"}
            component={ShieldRepairNetworkServicePartnership}
          />

          <Route exact path="/careers" component={Careers} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/contact" component={ContactUs} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/shipping-policy" component={ShippingPolicy} />
          <Route path="/warranty-information" component={TireWarranty} />
          <Route path="/fleet-services" component={FleetServices} />
          <Route path="/vip-membership" component={VipMembership} />
          <Route exact path="/jason" component={TestPage} />

          <Route path={CREDIT_PAGE_ENDPOINT} component={SyncronyCredit} />

          <PrivateRoute exact path="/wholesale" component={WholeSale} />
          <Route exact path="/login" component={WholesaleLogin} />

          <Route
            exact
            path={SMOG_CHECK_CONFIG.endPoint}
            component={SmogCheck}
          />
          <Route exact path={"/quick-link"} component={QuickLink} />
        </MainLayout>

        <Redirect to="/" />
      </Switch>
      <ModalController />
    </Container>
  );
};

export default Routes;
