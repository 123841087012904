import MetaTags from "../../components/reusable/ReactMetaTags.jsx";
import { Box } from "@material-ui/core";
import APP_ENV from "../../appEnvironment.js";
import dayjs from "dayjs";
import useHotDealsStore from "../../hooks/store/hot-deals-store.js";
import StandardPageHeader from "../../components/generic/standard-page-header.js";
import DefaultContainer from "../../components/generic/default-container.js";
import { CouponSecondaryOffersGrid } from "../../components/coupons/coupon-secondary-offers.js";
import CouponPageSectionContainer from "../../components/coupons/widgets/coupon-page-section-container.js";
import CouponFreeServices from "../../components/coupons/coupon-free-services.js";
import CouponTireMailInRebates from "../../components/coupons/coupon-tire-mail-in-rebates.js";
import MainHeroImage from "../../components/promotions/full-site-tire-sale/main-hero-image.js";
import SyncronyRebateCouponsPage from "../../components/promotions/syncrony-rebate/syncrony-rebate-coupons-page.js";
import FreeCVI from "../../components/promotions/widgets/free-cvi.js";

const { theme } = APP_ENV;

const theMonth = dayjs().format("MMMM");

const CouponsMain = () => {
  const [activeTireRebates, expiredTireRebates] = useHotDealsStore((st) => [
    st.activeTireRebates,
    st.expiredTireRebates,
  ]);

  const isEmpty = !Boolean(expiredTireRebates);

  return (
    <>
      <MetaTags
        description="Find a big discount on your tires, oil change, brakes, wheels, alignment, and more with our current coupons and rebates. Shop today!"
        canonical="/coupons"
        title={`Coupons and Hot Deals at ${APP_ENV.appBrandName}`}
      />
      <StandardPageHeader
        title={`${theMonth} Hot Deals & Savings`}
        subtitle="Save big on your vehicle repairs with our exclusive online offers."
      />
      <DefaultContainer backgroundColor={theme.palette.background.default}>
        <Box
          style={{
            paddingTop: 25,
            paddingBottom: 25,
          }}
        >
          <CouponPageSectionContainer title={`${theMonth} Hot Deals`}>
            {/* <CouponPrimaryOffers /> */}
            <MainHeroImage />
            <FreeCVI />
          </CouponPageSectionContainer>
          <CouponPageSectionContainer title={"Promotional Financing"}>
            <SyncronyRebateCouponsPage />
          </CouponPageSectionContainer>

          <CouponPageSectionContainer title={`Exclusive Coupons`}>
            <CouponSecondaryOffersGrid />
          </CouponPageSectionContainer>
          <CouponPageSectionContainer title={`Tire Savings & Rebates`}>
            <CouponTireMailInRebates rebates={activeTireRebates} />
          </CouponPageSectionContainer>
          <CouponPageSectionContainer title={`FREE Service Offers`}>
            <CouponFreeServices />
          </CouponPageSectionContainer>
          {isEmpty && (
            <CouponPageSectionContainer title={`Expired Tire Rebates`}>
              <CouponTireMailInRebates rebates={expiredTireRebates} />
            </CouponPageSectionContainer>
          )}
        </Box>
      </DefaultContainer>
    </>
  );
};

export default CouponsMain;
