import create from "zustand";
import { getTireSizes } from "../../services/venom-service";
import CLIENT_STORAGE from "./client/storage/client-storage";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const DEFUALT_TIRE_SIZE = "225-45-17";

let isReady;

const TireSizeStore = create((set, get) => {
  const initialState = {
    tireSizes: [],
    userTireSize: null,
  };

  let promise;

  const getTireSizeStoreAsync = async () => {
    if (!isReady && !promise) {
      promise = (async () => {
        const data = await getTireSizes();

        const tireSizes = data.map((ts) => ({
          ...ts,
          setSelected: () => {
            set({ userTireSize: ts });
            CLIENT_STORAGE.tireSize.setItem(ts.tireSizeEndPoint);
            return ts;
          },
        }));

        const userCookie = CLIENT_STORAGE.tireSize.getItem();
        let userTireSize = tireSizes.find(
          ({ tireSizeEndPoint }) => tireSizeEndPoint == userCookie
        );
        if (!userTireSize) {
          userTireSize = tireSizes.find(
            ({ tireSizeEndPoint }) => tireSizeEndPoint == DEFUALT_TIRE_SIZE
          );
        }
        userTireSize && userTireSize.setSelected();
        set({ tireSizes });
        isReady = true;
        promise = null;
        return;
      })();
    }

    if (!isReady) {
      await promise;
    }

    return get();
  };

  const tireSizeController = async ({
    width = null,
    ratio = null,
    rim = null,
    tireSizeEndPoint = null,
  } = {}) => {
    const { tireSizes } = await getTireSizeStoreAsync();

    let response;
    switch (true) {
      case tireSizeEndPoint:
        response = tireSizes.find(
          (ts) => ts.tireSizeEndPoint == tireSizeEndPoint
        );
        break;
      case !width:
        response = [...new Set(tireSizes.map((ts) => ts.width))];
        break;
      case !ratio:
        response = [
          ...new Set(
            tireSizes.filter((ts) => ts.width == width).map((ts) => ts.ratio)
          ),
        ];
        break;
      case !rim:
        response = [
          ...new Set(
            tireSizes
              .filter((ts) => ts.width == width && ts.ratio == ratio)
              .map((ts) => ts.rim)
          ),
        ];
        break;
      default:
        response = tireSizes.find((ts) =>
          Boolean(ts.width == width && ts.ratio == ratio && ts.rim == rim)
        );
    }

    return response;
  };

  return {
    ...initialState,
    getTireSizeStoreAsync,
    tireSizeController,
  };
});

export const useTireSizeStore = (selectorFunc = (store) => store) => {
  const [getTireSizeStoreAsync] = TireSizeStore((st) => [
    st.getTireSizeStoreAsync,
  ]);

  const { tireSizeEndPoint } = useParams();

  useEffect(() => {
    (async () => {
      if (!isReady || tireSizeEndPoint) {
        const _state = await getTireSizeStoreAsync();

        if (
          tireSizeEndPoint &&
          _state.userTireSize.tireSizeEndPoint !== tireSizeEndPoint
        ) {
          _state.tireSizes
            .find((ts) => ts.tireSizeEndPoint == tireSizeEndPoint)
            ?.setSelected();
        }
      }
    })();
  }, [tireSizeEndPoint]);

  return TireSizeStore(selectorFunc);
};

export default TireSizeStore;
