import MetaTags from "../../reusable/ReactMetaTags";
import APP_ENV from "../../../appEnvironment";

const TireRotation = () => {
  return (
    <>
      <MetaTags
        description={`At ${APP_ENV.appBrandName} we always do free tire rotations, whether you pruchased
                your tires from us or not. Tire rotations are the quick and easiest tire maintenance. Same day
                appointments available.`}
        canonical="/car-services/tire-rotation"
        title={`Tire Rotation | ${APP_ENV.appBrandName}`}
      />
      <div>
        <p>
          <span>
            <strong>Why is it important to rotate your tires?</strong>{" "}
          </span>
        </p>
        <p>
          Under normal driving conditions, the front tires of your car typically
          wear faster than the rear tires. This is because the front tires
          receive the most impact from turning in addition to supporting the
          extra weight of the engine on front wheel drive cars. Regular tire
          rotation can help ensure even distribution of your tire wear as well
          as increase wheel traction. This increases your vehicle’s tire mileage
          and can extend your tires’ service life.
        </p>
        <p>
          <span>
            <strong>How often do I need a tire rotation?</strong>
          </span>
        </p>
        <p>
          A tire rotation is recommended every 6,000 to 8,000 miles to avoid
          uneven tread wear. For most drivers, tire rotation is recommended
          approximately every six months.
        </p>
        <p>
          <span>
            <strong>What is included in the tire rotation service?</strong>
          </span>
        </p>
        <p>
          {`Visit your local ${APP_ENV.appBrandName} location in order to
                    receive expert advice from our maintenance professionals regarding
                    which tire rotation pattern will be best for your vehicle. If you have
                    a full-size spare, it can be included in your rotation pattern. Here
                    are two common tire rotation patterns:`}
        </p>
        <p>
          The rearward cross pattern takes the front tires to the back while
          swapping left for right as the back tires move up to the front but
          stay in their respective left and right positions.
        </p>
        <img
          src="http://bbtcdn.blob.core.windows.net/images/Services/rotation1.png"
          alt="traditional rotation"
        />
        <p>
          An X-pattern swaps all tires from front to back, left to right.
          Finally, the front cross pattern takes the back tires to the front,
          swapping right for left while the front tires go to the back but will
          not be switched from left to right.
        </p>
        <img
          src="http://bbtcdn.blob.core.windows.net/images/Services/rotationx.png"
          alt="X rotation"
        />
      </div>
    </>
  );
};

export default TireRotation;
