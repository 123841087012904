import VyperLandingPage from "./template/vyper-landing-page";
import { STATIC_CART_ITEMS } from "../../../static/cart-items";

export const VYPER_25_OFF_AC = {
  promotionDirectory: "/ac-service-coupon",
  edgeItemId: 104967,
  activePromotions: [
    {
      endPoint: "25-off",
      stores: [
        "3020",
        "3025",
        "3026",
        "3027",
        "3028",
        "3029",
        "3030",
        "3031",
        "3032",
        "3033",
        "3034",
        "3035",
        "3001",
        "3002",
        "3003",
        "3004",
        "3005",
        "3006",
        "3007",
        "3008",
        "3009",
        "3010",
        "3011",
        "3012",
        "3013",
        "3014",
        "3015",
        "3016",
        "3017",
        "3018",
        "3019",
        "3021",
        "3022",
        "3023",
        "3024",
        "3036",
        "3037",
        "3200",
        "3201",
        "3202",
      ],
      story:
        "Being hot is not cool! Come in for a FREE Air Conditioning System inspection, and save $25 off any needed a/c related service.",
    },
    {
      endPoint: "25-off-reno",
      stores: ["3200", "3201", "3202", "1051"],
      story:
        "Being hot is not cool! Come in for a FREE Air Conditioning System inspection, and save $25 off any needed a/c related service.",
    },
  ],
};

const Vyper25OffAC = () => {
  return (
    <VyperLandingPage
      lineOneText="Get $25 Off"
      lineTwoText="A/C Service Now"
      lineThreeText="*Includes FREE A/C Inspection"
      buttonText="Get Coupon Now"
      promotionImage={
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/AC-service-landing-page_rgular.gif"
      }
      promotionCartItem={STATIC_CART_ITEMS.AC_SERVICE_25_OFF}
      activePromotions={VYPER_25_OFF_AC.activePromotions}
    />
  );
};

export default Vyper25OffAC;
