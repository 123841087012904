import useZState from "./useZState";

export const createSelectionOption = (value, label, props = {}) => ({
  value,
  label,
  ...props,
});

export const createSelectionField = (label, key, fetchData) => ({
  label,
  key,
  fetchData,
});

const useConditionalSelection = (
  getInitialSelections,
  selectionFields,
  onFinalSelection,
  onSubmit
) => {
  const [get, setState] = useZState({
    initComplete: false,
    isLoading: false,
    selections: [],
    fields: [],
    hasError: false,
    isReady: false,
    finalSelection: null,
    promise: null,
  });

  const set = (obj) => {
    let { hasError, fields } = get();
    if (!obj.hasOwnProperty("hasError")) {
      if (hasError) {
        obj.hasError = false;
        fields = obj?.fields ?? fields;
        obj.fields = fields.map((field) => ({ ...field, hasError: false }));
      }
    }

    setState(obj);
  };

  const handleInit = async () => {
    initFields();

    const _state = get();
    const _fields = _state.fields;

    let initValues = await getInitialSelections();

    initValues = initValues.filter((i) => i || i == 0);
    if (initValues.length < _fields.length) {
      initValues.length = 0;
    }

    const initParams = [
      [],
      ...initValues.map((_, i) => initValues.slice(0, i + 1)),
    ].filter((_, i) => i < _fields.length);

    await Promise.all(
      initParams.map((params, i) => _fields[i].getOptions(_fields, params))
    );

    const initSelections = initValues.map((val, i) =>
      get().fields[i].options.find((op) => op.value == val)
    );

    await new Promise((resolve) => {
      const handleSelect = async (index = 0) => {
        const { setSelected = null } = initSelections?.[index] ?? {};
        if (setSelected) {
          await setSelected();
          handleSelect(index + 1);
        } else {
          resolve();
        }
      };
      handleSelect();
    });

    set({ initComplete: true });
  };

  const initFields = () => {
    const fields = selectionFields.map((field, index) => {
      const getOptions = async (_fields, _params) => {
        const paramsChanged = _params.some(
          (param, i) => param !== _fields[index].params?.[i]
        );

        let { isReady, finalSelection } = get();

        if (paramsChanged) {
          _fields = _fields.map((field, i) => {
            if (i >= index) {
              field.options = [];
              field.active = false;
              field.isDisabled = true;
              field.selection = null;
              field.params = [];
            }
            return field;
          });
          isReady = false;
          finalSelection = null;
        }

        const _currField = _fields[index];
        _currField.isDisabled = false;
        _currField.isActive = get().initComplete ? true : false;

        _fields[index] = _currField;
        const isLoading = !Boolean(_currField.options.length);

        set({
          isLoading,
          isReady,
          fields: _fields,
          selections: _params,
          finalSelection,
        });

        if (isLoading) {
          const data = await _currField.fetchData(_params);
          _currField.params = _params;
          _currField.options = data.map((option) => {
            return {
              ...option,
              isSelected: false,
              setSelected: async () =>
                await handleSelection(option, [..._params, option.value]),
            };
          });

          _fields[index] = _currField;
        }

        set({ isLoading: false, fields: _fields });

        if (_currField.options.length === 1) {
          await _currField.options[0].setSelected();
        }
      };

      const handleSelection = async (option, _selections) => {
        const _state = get();
        const _fields = _state.fields;
        const _currField = _fields[index];

        const { value } = option;
        const nextField = _fields?.[index + 1];
        const valueChanged = _currField.selection?.value !== value;

        _currField.selection = option;
        _currField.isActive = false;
        _currField.options = _currField.options.map((op) => ({
          ...op,
          isSelected: op.value == value,
        }));
        _fields[index] = _currField;

        if (nextField) {
          nextField.getOptions(_fields, _selections);
          return;
        }

        set({
          isReady: true,
          fields: _fields,
          finalSelection: !valueChanged ? _state.finalSelection : null,
        });

        setAllFieldsInactive();

        let { promise, finalSelection } = get();

        if (finalSelection) return;

        if (!promise) {
          set({ promise: onFinalSelection(_selections) });
        }

        finalSelection = await get().promise;

        set({
          finalSelection,
          promise: null,
        });
      };

      const setActive = () => {
        let { fields } = get();
        fields = fields.map((field, i) => ({
          ...field,
          isActive: Boolean(i == index),
        }));
        set({ fields });
      };

      const showBackButton = Boolean(index > 0);

      const goBack = () => {
        if (showBackButton) {
          let prevField = get().fields[index - 1];
          prevField.setActive();
        }
      };

      return {
        ...field,
        selection: null,
        params: [],
        options: [],
        isActive: false,
        isDisabled: true,
        getOptions,
        setActive,
        goBack,
        showBackButton,
        hasError: false,
      };
    });

    set({ fields });
  };

  const setAllFieldsInactive = () => {
    let { fields } = get();
    fields = fields.map((field) => ({ ...field, isActive: false }));
    set({ fields });
  };

  const handleSubmit = async () => {
    let { isReady, fields, finalSelection, promise } = get();

    if (isReady) {
      if (!finalSelection) {
        set({ isLoading: true });
        finalSelection = await promise();
        set({ isLoading: false });
      }

      if (finalSelection) {
        const selectedValues = Object.assign(
          {},
          ...fields.map(({ key, selection }) => ({ [key]: selection }))
        );

        onSubmit(finalSelection, selectedValues);
        return;
      }
    }

    fields = fields.map((field) => ({
      ...field,
      hasError: Boolean(!field.selection),
    }));

    set({ fields, hasError: true });
  };

  const state = get();
  return {
    ...state,
    handleInit,
    setAllFieldsInactive,
    handleSubmit,
    selectionActive: state.fields.some(({ isActive }) => isActive),
  };
};

export default useConditionalSelection;
