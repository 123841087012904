import {
  Button,
  Paper,
  Popover,
  Box,
  Typography,
  Divider,
  Link,
} from "@material-ui/core";
import useAnchorEl from "../../../hooks/util/useAnchorEl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import APP_ENV from "../../../appEnvironment";
import TireItemDiscountsCollection from "./tire-item-discounts-collection";

const { theme } = APP_ENV;

const TireItemSaveOn4Button = ({ tireItemQtyFour = {} }) => {
  const { anchorEl, isOpen, setClosed, toggle } = useAnchorEl();

  const { _promotion, _totalDiscountsAvailable } = tireItemQtyFour;

  return (
    <>
      {_totalDiscountsAvailable > 0 && (
        <>
          <Box onClick={toggle}>
            <Button
              fullWidth
              style={{
                backgroundColor: _promotion?.sitePromoColors?.tertiary,
                color: theme.palette.primary.contrastText,
                fontSize: 13,
                maxWidth: 300,
                height: 40,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              variant="contained"
              endIcon={!isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            >
              {`Save $${_totalDiscountsAvailable.toFixed(2)} on 4 Tires`}
            </Button>
            {_promotion?.promoType && (
              <Box
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  border: `1px solid ${_promotion.sitePromoColors.tertiary}`,
                  borderRadius: 5,
                  padding: 5,
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  Special Offer
                </Typography>
                <Divider style={{ marginTop: 3, marginBottom: 3 }} />
                <Typography style={{ fontSize: 12, textAlign: "center" }}>
                  {`${_promotion.displayText.alertBar}*`}
                </Typography>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      fontSize: 13,
                      color: _promotion.sitePromoColors.tertiary,
                    }}
                  >
                    View Details
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Popover
            open={isOpen}
            onClose={setClosed}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Paper style={{ padding: 15, maxWidth: 300 }}>
              <TireItemDiscountsCollection activeTireByQty={tireItemQtyFour} />
            </Paper>
          </Popover>
        </>
      )}
    </>
  );
};

export default TireItemSaveOn4Button;
