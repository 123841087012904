import VyperLandingPage from "./template/vyper-landing-page";
import { STATIC_CART_ITEMS } from "../../../static/cart-items";

export const VYPER_AC_25_OFF_ICE_CREAM = {
  promotionDirectory: "/ac-service",
  edgeItemId: 104961,
  activePromotions: [
    {
      endPoint: "25-off-ice-cream",
      stores: [
        "3203",
        "3204",
        "3205",
        "3206",
        "3207",
        "3208",
        "3209",
        "3210",
        "3211",
        "3212",
        "3213",
        "3214",
      ],
      story:
        "Being hot is not cool! Come in for a FREE Air Conditioning System inspection, and save $25 off any needed a/c related service PLUS receive a FREE $10 Handel's Ice Cream gift card!",
    },
  ],
};

const Vyper25OffAcIceCream = () => {
  return (
    <VyperLandingPage
      lineOneText="Get $25 Off"
      lineTwoText="A/C Service Now"
      lineThreeText="*Includes $10 Handel's Ice Cream Gift Card"
      buttonText="Get Coupon Now"
      promotionImage={
        "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/AC-COUPON.gif"
      }
      promotionCartItem={STATIC_CART_ITEMS.AC_SERVICE_25_OFF_WITH_ICE_CREAM}
      activePromotions={VYPER_AC_25_OFF_ICE_CREAM.activePromotions}
    />
  );
};

export default Vyper25OffAcIceCream;
