import { useEffect } from "react";
import APP_ENV from "../appEnvironment";
import { useLocationStore } from "../hooks/store/location-store";
import useZState from "../hooks/util/useZState";
import { CREDIT_PAGE_ENDPOINT } from "./store/credit-store";

const getFooterLinks = (userStore = {}) => {
  const commonLinksSection = {
    title: APP_ENV.appBrandName,
    links: [
      {
        href: "/locations",
        linkText: "Find A Store",
      },
      {
        href: "/locations-directory",
        linkText: "View All Locations",
      },
      { href: "/about-us", linkText: "About Us" },
      { href: "/coupons", linkText: "Hot Deals" },
      {
        href: "/fleet-services",
        linkText: "Fleet Services",
      },
      {
        href: "/terms-of-use",
        linkText: "Terms Of Use",
      },
      {
        href: "/privacy-policy",
        linkText: "Privacy Policy",
      },
      {
        href: "/shipping-policy",
        linkText: "Shipping Policy",
      },
      {
        href: "/warranty-information",
        linkText: "Tire Warranty",
      },
      {
        href: CREDIT_PAGE_ENDPOINT,
        linkText: `${APP_ENV.appBrandName} Credit`,
      },
    ],
  };

  const wholesale = {
    display: APP_ENV.appBrandId == 1,
    title: "Wholesale",
    links: [
      {
        href: "/wholesale",
        linkText: "Wholesale",
      },
    ],
  };

  const automotiveServices = {
    title: "Automotive Services",
    links: [
      {
        href: "/tires",
        linkText: "Tires",
      },
      {
        href: "/oilrecommendations",
        linkText: "Oil Change",
      },
      {
        href: "/car-services/brake-check",
        linkText: "Brakes",
      },
      {
        href: "/car-services/battery-check",
        linkText: "Batteries",
      },
      {
        href: "/electric-vehicle-tires",
        linkText: "EV Tires",
      },
      ...[
        {
          href: userStore?.smogCheck?.endPoint,
          linkText: "Smog Check",
        },
      ].filter(({ href }) => href),
    ],
  };

  const tireBrands = {
    title: "Tire Brands",
    links: [
      {
        href: "/tires/michelin",
        linkText: "Michelin",
      },
      {
        href: "/tires/cooper",
        linkText: "Cooper",
      },
      {
        href: "/tires/pirelli",
        linkText: "Pirelli",
      },
      {
        href: "/tires",
        linkText: "All Tire Brands",
      },
    ],
  };

  const careers = {
    title: "Careers",
    links: [
      {
        href: "/careers",
        linkText: "View Available Positions",
      },
    ],
  };

  const partnerships = {
    title: "Partnerships",
    links: [
      {
        href: "/service-partnerships/silver-rock",
        linkText: "SilverRock",
      },
      {
        href: "/service-partnerships/shield-repair-network",
        linkText: "Shield Repair Network",
      },
    ],
  };

  const about = {
    title: `About ${APP_ENV.appBrandName}`,
    links: [
      {
        href: "/about-us",
        linkText: "History",
      },
      {
        href: "/about-us",
        linkText: "Vision Statement",
      },
    ],
  };

  const FOOTER_LINKS = {
    commonLinksSection,
    wholesale,
    automotiveServices,
    tireBrands,
    careers,
    partnerships,
    about,
  };

  return Object.assign(
    { ...FOOTER_LINKS },
    ...Object.entries(FOOTER_LINKS).map(([key, values]) => ({
      [key]: {
        display: Boolean(values?.links?.length && values?.display !== false),
        title: "",
        links: [],
        ...values,
      },
    }))
  );
};

const useFooterLinks = () => {
  const [get, set] = useZState({
    storeNumber: null,
    footerLinks: getFooterLinks(),
  });

  const [userStore] = useLocationStore((st) => [st.userStore]);

  useEffect(() => {
    if (
      userStore?.storeNumber &&
      userStore?.storeNumber !== get().storeNumber
    ) {
      set({
        storeNumber: userStore.storeNumber,
        footerLinks: getFooterLinks(userStore),
      });
    }
  }, [userStore]);

  return get().footerLinks;
};

export default useFooterLinks;
