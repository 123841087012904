import { Box, Button } from "@material-ui/core";
import VehicleTireSelectionModal from "./widgets/VehicleTireSizeSelectionModal";
import SelectVehicle from "../reusable/conditional-selection/SelectVehicle";

const SelectVehicleOnlyModalDesktop = ({ isOpen, closeModal }) => {
  const SelectVehicleComp = () => {
    const { component, handleSubmit } = SelectVehicle(closeModal);
    return (
      <Box>
        {component}
        <Button
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          color={"primary"}
          style={{ marginTop: 20 }}
        >
          Submit
        </Button>
      </Box>
    );
  };

  return (
    <VehicleTireSelectionModal
      isOpen={isOpen}
      closeModal={closeModal}
      tabs={[{ tabLabel: "Select Vehicle", isActive: true }]}
      currentStep={1}
      sectionTitle={"Select your Vehicle"}
      sectionDesc={
        "All we need is your vehicle year, make, and model to find the best deals."
      }
    >
      {isOpen && <SelectVehicleComp />}
    </VehicleTireSelectionModal>
  );
};

export default SelectVehicleOnlyModalDesktop;
