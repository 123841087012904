import React from "react";
import { useState, useContext } from "react";
import { Box, Button, Paper, Grid, Divider, Collapse } from "@material-ui/core";
import TireItemChipMain from "../promotions/full-site-tire-sale/tire-item-chip-main";
import TireItemChipSecondary from "../promotions/full-site-tire-sale/tire-item-chip-secondary";
import BrandModelSize from "./widgets/brand-model-size";
import TireImage from "./widgets/tire-image";
import TirePackageAddToCartCollection from "./widgets/tire-package-add-to-cart-collection";
import TireFeaturesCollection from "./widgets/tire-features-collection";
import TireSearchResultsExpandible from "./widgets/tire-search-results-expandible";
import { ExpandMoreRounded, ExpandLessRounded } from "@material-ui/icons";
import TireSpecsCollection from "./widgets/tire-specs-collection";
import ReviewStars from "./widgets/review-stars";
import { TireSearchResultsCardContext } from "./tire-search-results-card";

const TireSearchResultsCardMobile = () => {
  const { tire } = useContext(TireSearchResultsCardContext);
  const [state, setState] = useState({
    showSpecs: false,
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div style={{ maxWidth: 550 }}>
        <Paper style={{ marginBottom: 15, width: "100%" }} elevation={1}>
          <Grid container>
            <Grid item xs={12}>
              <TireItemChipMain {...tire} />

              <TireItemChipSecondary {...tire} />

              <Box style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Box>
                  <BrandModelSize {...tire} />
                </Box>
                <ReviewStars {...tire} linkHref={tire.itemSlug} />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Grid item xs={4}>
                <TireImage
                  {...tire}
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    height: 200,
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TirePackageAddToCartCollection {...tire} />
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 10 }}>
              <TireFeaturesCollection {...tire} />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <Grid item xs={12}>
              <TireSearchResultsExpandible tire={tire} />
              <Divider />
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Button
                    style={{ fontSize: 12 }}
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        showSpecs: !prev.showSpecs,
                      }))
                    }
                    endIcon={
                      state.showSpecs ? (
                        <ExpandLessRounded />
                      ) : (
                        <ExpandMoreRounded />
                      )
                    }
                  >
                    View Tire Specs
                  </Button>
                  <Collapse in={state.showSpecs}>
                    <Box>
                      <TireSpecsCollection {...tire} />
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default TireSearchResultsCardMobile;
