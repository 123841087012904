import { Box, Typography } from "@material-ui/core";
import APP_ENV from "../../../appEnvironment";

const AboutOurCompany = ({ cityState = "" }) => {
  return (
    <Box style={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        {`About ${APP_ENV.appBrandName}`}
      </Typography>
      <Typography variant="body1" component={"p"}>
        {`${APP_ENV.appBrandName} is a full service auto repair facility where 
         you will find honest, ethical, and female friendly auto
         service ${cityState ? cityState : "near you"}. ${
          APP_ENV.appBrandName
        } locations have
         been keeping communities moving for years with quality automotive
         inspections, repairs, and regularly scheduled maintenance services.
         Now our company is growing across the nation to provide you with
         facilities that are equipped with state of the art computer technology
         in order to diagnose and service your vehicle.`}
      </Typography>
    </Box>
  );
};

export default AboutOurCompany;
