import create from "zustand";
import { v4 as uuidv4 } from "uuid";
import AllTiresStore from "./all-tires-store";

const TireSearchFilterStore = create((set, get) => {
  const setState = (func = () => {}) =>
    set((prev) => {
      const _state = func(prev);
      const { filteredTires, sorts } = _state;
      const tireSet = sorts.options
        .find(({ isSelected }) => isSelected)
        .sortFunc(filteredTires);

      return {
        ..._state,
        tireSetId: uuidv4(),
        tireSet,
      };
    });

  const FILTERS = [
    [
      "Brand",
      "brandEndPoint",
      (value, tires) =>
        tires.find((item) => item.brandEndPoint == value)?.brandName,
      (values) => values.sort(),
    ],
    [
      "Speed Rating",
      "speedRatingId",
      (value, tires) => {
        const { speedRating, milesPerHour } = tires.find(
          (item) => item.speedRatingId == value
        );
        return `${speedRating} Rated: ${milesPerHour}mph`;
      },
      (values) => values.sort((a, b) => a - b),
    ],
    [
      "Load Range",
      "loadRangeId",
      (value, tires) =>
        tires.find((item) => item.loadRangeId == value)?.loadRange,
      (values) => values.filter((i) => i && i !== 14).sort((a, b) => a - b),
    ],
    [
      "Run-Flat",
      "isRunFlat",
      (value) => (Boolean(value) ? "Run-Flat Tires" : "Non Run-Flat Tires"),
      (values) => values.sort().reverse(),
    ],
    [
      "Winter",
      "isSnowRated",
      () => "Winter Tires Only",
      (values) => values.filter((i) => i),
    ],
  ].map(
    ([
        label,
        tireItemKey,
        mapValuesToLabels,
        sortLabels = (labels) => labels,
      ]) =>
      (tires = []) => ({
        label,
        key: tireItemKey,
        options: sortLabels([
          ...new Set(tires.map((item) => item[tireItemKey])),
        ]).map((value) => ({
          value,
          label: mapValuesToLabels(value, tires),
          isSelected: false,
          isDisabled: false,
          onSelect: () => {
            const _filters = get().filters.map((_filter) =>
              _filter.key !== tireItemKey
                ? _filter
                : {
                    ..._filter,
                    options: _filter.options.map((opt) => ({
                      ...opt,
                      isSelected:
                        opt.value == value ? !opt.isSelected : opt.isSelected,
                    })),
                  }
            );

            const { allTires } = AllTiresStore.getState();

            const getFilteredTires = (filterOptSelector = () => null) => {
              const activeFilters = _filters.map((_filter) => [
                _filter.key,
                _filter.options
                  .filter((_option) => filterOptSelector(_filter, _option))
                  .map(({ value }) => value),
              ]);

              return allTires.filter((tireItem) =>
                activeFilters.every(
                  ([key, values]) =>
                    !values.length || values.includes(tireItem[key])
                )
              );
            };

            const filters = _filters.map((filter) => ({
              ...filter,
              options: filter.options.map((option) => {
                const isDisabled = !getFilteredTires(
                  (_filter, _option) =>
                    _option.isSelected ||
                    (filter.key == _filter.key && _option.value == option.value)
                ).some((tire) => tire[filter.key] == option.value);
                return {
                  ...option,
                  isSelected: !isDisabled ? option.isSelected : false,
                  isDisabled,
                };
              }),
            }));

            const filteredTires = getFilteredTires(
              (_filter, _option) => _option.isSelected
            );

            setState((prev) => ({ ...prev, filters, filteredTires }));
          },
        })),
      })
  );

  const SORTS = {
    label: "Sort By",
    options: [
      [
        "Top Recommended",
        "top-recommended",
        (tires) =>
          tires.sort((a, b) => a.originalSortIndex - b.originalSortIndex),
      ],
      [
        "Price High to Low",
        "price-high-to-low",
        (tires) => tires.sort((a, b) => b.price - a.price),
      ],
      [
        "Price Low to High",
        "price-low-to-high",
        (tires) => tires.sort((a, b) => a.price - b.price),
      ],
    ].map(([label, value, sortFunc], i) => ({
      label,
      value,
      sortFunc: (tires) => sortFunc([...tires]),
      isSelected: Boolean(i == 0),
      onSelect: () =>
        setState((prev) => ({
          ...prev,
          sorts: {
            ...prev.sorts,
            options: prev.sorts.options.map((_option) => ({
              ..._option,
              isSelected: _option.value == value,
            })),
          },
        })),
    })),
  };

  return {
    totalTireCount: 0,
    filters: FILTERS.map((init) => init([])),
    sorts: SORTS,
    initializeTireFilters: (allTires = []) => {
      const tires = allTires.map((tire, i) => ({
        ...tire,
        originalSortIndex: i,
      }));
      setState((prev) => ({
        ...prev,
        totalTireCount: allTires.length,
        filters: FILTERS.map((init) => init(tires)),
        filteredTires: tires,
        sorts: SORTS,
      }));
    },
  };
});

export default TireSearchFilterStore;
