import { useEffect, useState } from "react";
import APP_ENV from "../../appEnvironment";
import { roundMoney } from "../../helpers/generic";
import { useLocationStore } from "./location-store";

/*
 */

const APP_CREDIT_CARD_OPTIONS = {
  1: {
    creditCardName: APP_ENV.appBrandName,
    tileImage:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/rebate-BBTS-credit-card_updated.png",
    mainImage:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/rebate-BBTS-credit-card_updated.png",
    currentRebate: "",
    prevRebate:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/SYF_CC-0684%20-%20BBT%20July%20Rebate%202024%20(8.5x11)%20Rebate%20Form.pdf",
    preQualifyLink:
      "https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=1F8AA81EA05783504DBEDB2AF7C5E33F924C38DE63C9DECC&preQual=Y",
    applyNowLink:
      "https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=1F8AA81EA05783504DBEDB2AF7C5E33F924C38DE63C9DECC&client=Big%20Brand%20Tire",
    manageAccountLink:
      "https://www.mysynchrony.com/cmp-index.html?market=automotive&store=bigbrandtire",
    estimateMonthlyPaymentLink:
      "https://www.mysynchrony.com/payment-calculator/syfcarcare-6",
    synchronyRewardCenterLink : 
      "https://synchronycarcare.sendmyrewards.com/?offer=24-80362",  
    financeCalculatorProps: {
      financeMonths: 6,
      financeMinimum: 199.99,
    },
  },

  2: {
    creditCardName: "Synchrony",
    tileImage:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/rebate-TW-credit-card_updated.jpg",
    mainImage:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/rebate-TW-credit-card_updated.jpg",
    currentRebate: "",
    prevRebate:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/syncrony-rebate-ends-02292024-bbt.pdf",
    preQualifyLink:
      "https://www.mysynchrony.com/mmc/NT218256400?page=no&product=prequal",
    applyNowLink:
      "https://www.mysynchrony.com/mmc/NT218256400?page=no&product=apply",
    manageAccountLink:
      "https://consumercenter.mysynchrony.com/consumercenter/login/",
    estimateMonthlyPaymentLink:
      "https://www.mysynchrony.com/payment-calculator/syfcarcare-6",
    synchronyRewardCenterLink : 
      "https://synchronycarcare.sendmyrewards.com/?offer=24-80362",  
    financeCalculatorProps: {
      financeMonths: 6,
      financeMinimum: 199.99,
    },
  },

  3: {
    creditCardName: APP_ENV.appBrandName,
    tileImage:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/rebate-ATD-credit-card_updated.jpg",
    mainImage:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/rebate-ATD-credit-card_updated.jpg",
    currentRebate: "",
    prevRebate:
      "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/SYF_CC-0685%20-%20ATD%20July%20Rebate%202024%20(8.5x11)%20Rebate%20Form.pdf",
    preQualifyLink:
      "https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=1F8AA81EA0578350E9150C26F3A51E6B9FCB1675AD9938BF&preQual=Y",
    applyNowLink:
      "https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=1F8AA81EA0578350E9150C26F3A51E6B9FCB1675AD9938BF&client=American%20Tire%20Depot",
    manageAccountLink:
      "https://www.mysynchrony.com/cmp-index.html?market=automotive&store=americantiredepot",
    estimateMonthlyPaymentLink:
      "https://www.mysynchrony.com/payment-calculator/syfcarcare-6",
    synchronyRewardCenterLink : 
      "https://synchronycarcare.sendmyrewards.com/?offer=24-80362",  
    financeCalculatorProps: {
      financeMonths: 6,
      financeMinimum: 199.99,
    },
  },
};

const { financeCalculatorProps = {}, ...appCreditInfo } =
  APP_CREDIT_CARD_OPTIONS[APP_ENV.appBrandId];

export const CREDIT_PAGE_ENDPOINT = "/credit";

const useAppCreditInformation = () => {
  const [state, setState] = useState({
    appCreditInfo,
  });

  const [userStore] = useLocationStore((st) => [st.userStore]);

  //this exists if you need to overwrite credit info based on store, not based on brand.
  useEffect(() => {
    if (userStore) {
      setState((prev) => ({
        appCreditInfo: {
          ...appCreditInfo,
        },
      }));
    }
  }, [userStore]);

  return {
    creditPageEndPoint: CREDIT_PAGE_ENDPOINT,
    ...state.appCreditInfo,
    monthlyCreditCalculator: (amount = 0) => {
      const { financeMinimum = 200, financeMonths = 0 } =
        financeCalculatorProps;
      const amountQualifies = financeMonths && amount >= financeMinimum;
      return {
        amountQualifies,
        monthlyPaymentAmount: roundMoney(
          amountQualifies ? amount / financeMonths : 0
        ),
        financeMonths,
      };
    },
  };
};

export default useAppCreditInformation;
