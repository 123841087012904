import { Box, Paper, Button, Grid, Link } from "@material-ui/core";
import { STATIC_CART_ITEMS } from "../../../static/cart-items";
import APP_ENV from "../../../appEnvironment";

const { theme } = APP_ENV;

const FreeCVI = () => {
  const { COMPREHENSIVE_VEHICLE_INSPECTION } = STATIC_CART_ITEMS;

  return (
    <Grid container>
      {[
        {
          image:
            "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/cvi-image.png",
          buttonLabel: "Add To Cart",
          buttonProps: {
            onClick: COMPREHENSIVE_VEHICLE_INSPECTION.addToCart,
          },
        },
        {
          image:
            "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Valvoline_rebate-july.jpg",
          buttonLabel: "See Details",
          buttonProps: {
            component: Link,
            href: `https://venomstorage.blob.core.windows.net/bbtwebblob/promos/US_DIG_3445_EN_VPERKS_SUMMER_PROMO_DIGITAL_TEARPAD_DEALER.pdf`,
            target: "_blank",
          },
        },
      ].map(({ image, buttonLabel, buttonProps }, i) => (
        <Grid item xs={12} md={6} style={{ padding: 25 }} key={i}>
          <Paper
            style={{ width: "fit-content", cursor: "pointer" }}
            elevation={8}
          >
            <img
              src={image}
              style={{
                width: "100%",
                height: "auto",
                cursor: "pointer",
                maxWidth: 750,
              }}
            />
          </Paper>
          <Box style={{ paddingTop: 25, textAlign: "center" }}>
            <Button
              variant={"contained"}
              color={"primary"}
              style={{
                width: 300,
                height: 50,
                color: theme.palette.primary.contrastText,
              }}
              {...buttonProps}
            >
              {buttonLabel}
            </Button>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default FreeCVI;
