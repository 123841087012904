import React, { createContext, useState } from "react";
import LayoutStore from "../../hooks/store/client/layout-store";
import TireSearchResultsCardMobile from "./tire-search-results-card-mobile";
import TireSearchResultsCardDesktop from "./tire-search-results-card-desktop";
import TireOptionTrackingStore from "../../hooks/store/tire-option-tracking-store";

export const TireSearchResultsCardContext = createContext();

const TireSearchResultsCard = ({ tire, children = null }) => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const [state, setState] = useState({
    selectedQty: 4,
    showDetails: true,
  });

  const setSelectedQty = (qty) =>
    setState((prev) => ({ ...prev, selectedQty: qty }));

  const setShowDetails = () =>
    setState((prev) => ({ ...prev, showDetails: !prev.showDetails }));

  const activeTireByQty = TireOptionTrackingStore.getState().addRenderedTire(
    tire,
    state.selectedQty
  );

  return (
    <TireSearchResultsCardContext.Provider
      value={{
        tire,
        activeTireByQty,
        contextState: { ...state, setSelectedQty, setShowDetails },
      }}
    >
      {!isMobile ? (
        <TireSearchResultsCardDesktop />
      ) : (
        <TireSearchResultsCardMobile />
      )}
      {children}
    </TireSearchResultsCardContext.Provider>
  );
};

export default TireSearchResultsCard;
