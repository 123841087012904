import create from "zustand";
import LocationStore from "./location-store";
import { getTireItems } from "../../services/venom-service";
import { TIRES_ROUTE_DIRECTORY } from "../../routes";
import CartStore, { CART_ITEM_TYPE } from "./cart-store";
import { VENOM_APPT_SERVICE_TYPE } from "./appointment-store";
import dayjs from "dayjs";
import { roundMoney } from "../../helpers/generic";
import { EDGE_ITEM_IDS } from "../../static/cart-items";
import ModalStore from "./modal-store";
import TireSizeStore from "./tire-size-store";
import SessionTimerStore from "./client/session-timer-store";
const minMax = require("dayjs/plugin/minMax");

dayjs.extend(minMax);

const TireItemsStore = create((set, get) => {
  const initialState = {
    prevParams: {},
    tiresByBrandModelCache: [],
    tiresByTireSizeCache: [],
  };

  const getActiveTireItems = async ({
    brandEndPoint = null,
    itemModelEndPoint = null,
    tireSizeEndPoint = null,
    partNumberEndPoint = null,
  }) => {
    const sessionId = SessionTimerStore.getState().sessionId;

    const { userStore } =
      await LocationStore.getState().getLocationStoreAsync();

    const { storeNumber } = userStore;

    const isBrandModelRequest = Boolean(brandEndPoint && itemModelEndPoint);
    const isTireSizeRequest = Boolean(tireSizeEndPoint);
    const isPartNumberRequest = Boolean(
      isBrandModelRequest && isTireSizeRequest && partNumberEndPoint
    );

    const isCartItemRequest = Boolean(
      brandEndPoint && partNumberEndPoint && !isPartNumberRequest
    );

    let { prevParams, tiresByBrandModelCache, tiresByTireSizeCache } = get();

    if (
      prevParams?.sessionId !== sessionId ||
      prevParams?.storeNumber !== storeNumber
    ) {
      tiresByBrandModelCache = [];
      tiresByTireSizeCache = [];
    }

    const relevantParamsLookupString = [
      TIRES_ROUTE_DIRECTORY,
      brandEndPoint,
      itemModelEndPoint,
      tireSizeEndPoint,
      partNumberEndPoint,
    ]
      .filter((i) => i)
      .join("/");

    const findTiresByItemSlug = (tire) => {
      return tire.itemSlug.includes(relevantParamsLookupString);
    };

    let activeTireItems = [];

    switch (true) {
      case isCartItemRequest:
        activeTireItems = await getTireItems({
          storeNumber,
          brandEndPoint,
          partNumberEndPoint,
          ispOnly: false,
        });
        break;
      case isPartNumberRequest:
        activeTireItems = tiresByBrandModelCache.filter(findTiresByItemSlug);
        if (!Boolean(activeTireItems.length)) {
          activeTireItems = tiresByTireSizeCache.filter(findTiresByItemSlug);
        }
        if (!Boolean(activeTireItems.length)) {
          activeTireItems = await getTireItems({
            storeNumber,
            brandEndPoint,
            itemModelEndPoint,
            tireSizeEndPoint,
            partNumberEndPoint,
          });
        }

        getActiveTireItems({
          brandEndPoint,
          itemModelEndPoint,
          tireSizeEndPoint,
        });
        break;
      case isBrandModelRequest:
        activeTireItems = tiresByBrandModelCache.filter(findTiresByItemSlug);
        if (!Boolean(activeTireItems.length)) {
          const cache = await getTireItems({
            storeNumber,
            brandEndPoint,
            itemModelEndPoint,
          });
          activeTireItems = cache.filter(findTiresByItemSlug);
          set((prev) => ({
            ...prev,
            prevParams: {
              ...prev.prevParams,
              sessionId,
              storeNumber,
              brandEndPoint,
              itemModelEndPoint,
            },
            tiresByBrandModelCache: cache,
          }));
        }

        if (isTireSizeRequest) {
          getActiveTireItems({
            tireSizeEndPoint,
          });
        }
        break;
      case isTireSizeRequest:
        if (
          Boolean(tiresByTireSizeCache.length) &&
          prevParams?.tireSizeEndPoint == tireSizeEndPoint
        ) {
          activeTireItems = tiresByTireSizeCache;
        } else {
          const { tireSizes } =
            await TireSizeStore.getState().getTireSizeStoreAsync();
          const tireSize = tireSizes.find(
            (i) => i.tireSizeEndPoint == tireSizeEndPoint
          );

          if (tireSize) {
            tireSize.setSelected();
            tireSizeEndPoint = tireSize.tireSizeEndPoint;

            const cache = await getTireItems({
              storeNumber,
              tireSizeEndPoint,
            });
            set((prev) => ({
              ...prev,
              prevParams: {
                ...prev.prevParams,
                sessionId,
                storeNumber,
                tireSizeEndPoint,
              },
              tiresByTireSizeCache: cache,
            }));
            activeTireItems = cache;
          }
        }

        break;
    }

    return await mapTireItemsToCartStore(activeTireItems, userStore);
  };

  const mapTireItemsToCartStore = async (activeTireItems, userStore) => {
    const { taxRate, tireInstallationPackage, tireSTF } = userStore;

    const [getCartItems, setCartItems] =
      CartStore.getState().useCartItemActions();

    const MAX_SELECT_QTY = 6;

    const qtySelectOptions = [...new Array(MAX_SELECT_QTY)].map(
      (_, i) => i + 1
    );

    const storeFallbackArrivesDateTime = userStore.storeSchedule
      .reduce(
        (prev, { storeClosed, openDateTime }) =>
          !storeClosed &&
          dayjs(openDateTime).isAfter(
            dayjs(userStore.currentDate).add(3, "day")
          )
            ? dayjs.min([prev, dayjs(openDateTime)])
            : prev,
        dayjs(userStore.currentDate).add(4, "day").add(8, "hour")
      )
      .add(3, "hour")
      .format("YYYY-MM-DDTHH:mm:ss");

    const mapTireDeliveryOptions = (deliveryAvailability = []) => {
      let maxTireArrivesDateTime = storeFallbackArrivesDateTime;

      const deliveryOptionsByQty = qtySelectOptions.map((qtyNeeded) => {
        const deliveryOption = deliveryAvailability.find(
          (i) => i.qtyNeeded == qtyNeeded
        );
        const qtyIsAvailable = Boolean(deliveryOption);
        let {
          arrivesDateTime = maxTireArrivesDateTime,
          fulfillmentDetails = [],
          orderBeforeDateTime = null,
          qtyIsInStock = false,
          requiresOutsideVendor = false,
        } = deliveryOption ?? {};

        if (dayjs(arrivesDateTime).isAfter(dayjs(maxTireArrivesDateTime))) {
          maxTireArrivesDateTime = arrivesDateTime;
        }

        const availabilityLabel = qtyIsInStock
          ? "In Stock"
          : !qtyIsAvailable
          ? "Call For Availability"
          : userStore.useCalendarDisplay(arrivesDateTime, {
              sameDay: "[Today]",
              nextDay: "[Tomorrow]",
              nextWeek: "dddd, MMM D",
              sameElse: "dddd, MMM D",
            });

        const arrivesDateTimeDisplay = qtyIsInStock
          ? "In Stock"
          : !qtyIsAvailable
          ? "Call For Availability"
          : userStore.useCalendarDisplay(arrivesDateTime, {
              sameDay: "[Today at] h:mm A",
              nextDay: "[Tomorrow at] h:mm A",
              nextWeek: "ddd, MMM D [at] h:mm A",
              sameElse: "ddd, MMM D [at] h:mm A",
            });

        const orderBeforeDateTimeDisplay =
          !qtyIsInStock && qtyIsAvailable
            ? userStore.useCalendarDisplay(orderBeforeDateTime, {
                sameDay: "[Today before] h:mm A",
                nextDay: "[Tomorrow before] h:mm A",
                nextWeek: "ddd, MMM D [before] h:mm A",
                sameElse: "ddd, MMM D [before] h:mm A",
              })
            : null;

        return {
          qtyNeeded,
          qtyIsInStock,
          qtyIsAvailable,
          requiresOutsideVendor,
          availabilityLabel,
          arrivesDateTime,
          arrivesDateTimeDisplay,
          orderBeforeDateTime,
          orderBeforeDateTimeDisplay,
          fulfillmentDetails,
        };
      });

      const deliveryOptionSummary = [
        ...new Set(
          deliveryOptionsByQty
            .filter((i) => i.qtyIsAvailable)
            .map(({ availabilityLabel }) => availabilityLabel)
        ),
      ].map((availabilityLabel) => {
        const optionsByLabel = deliveryOptionsByQty.filter(
          (i) => i.availabilityLabel == availabilityLabel
        );

        const qtyAvailable = optionsByLabel.length;

        const { orderBeforeDateTime, orderBeforeDateTimeDisplay } =
          optionsByLabel.find(
            (i) =>
              dayjs(i.orderBeforeDateTime).toISOString() ==
              dayjs
                .min(optionsByLabel.map((i) => dayjs(i.orderBeforeDateTime)))
                .toISOString()
          );

        const { arrivesDateTime, arrivesDateTimeDisplay } = optionsByLabel.find(
          (i) =>
            dayjs(i.orderBeforeDateTime).toISOString() ==
            dayjs
              .max(optionsByLabel.map((i) => dayjs(i.orderBeforeDateTime)))
              .toISOString()
        );

        return {
          isInStock: optionsByLabel.some(({ qtyIsInStock }) => qtyIsInStock),
          availabilityLabel,
          qtyAvailable,
          orderBeforeDateTime,
          orderBeforeDateTimeDisplay,
          arrivesDateTime,
          arrivesDateTimeDisplay,
        };
      });

      return {
        deliveryOptionsByQty,
        deliveryOptionSummary,
      };
    };

    activeTireItems = activeTireItems.map((item) => {
      const isPromoTire = userStore?.promotion?.brandEndPoints.includes(
        item.brandEndPoint
      );
      return {
        ...item,
        promotion: {
          ...(userStore?.promotion ?? {}),
          promoType: isPromoTire ? userStore.promotion.promoType : null,
        },
      };
    });

    const summitTireIndex = activeTireItems.findIndex(
      (item) =>
        item.brandEndPoint == "summit" &&
        Boolean(item.deliveryAvailability.length)
    );

    const promoTireIndex = activeTireItems.findIndex(
      (item, i) => i !== summitTireIndex && item.promotion.promoType
    );

    const summitTire = activeTireItems.filter((_, i) => i == summitTireIndex);
    const promoTire = activeTireItems.filter((_, i) => i == promoTireIndex);

    const regTires = activeTireItems.filter(
      (_, i) => ![summitTireIndex, promoTireIndex].includes(i)
    );

    const cartItemTires = [...summitTire, ...promoTire, ...regTires].map(
      (item) => {
        let { deliveryAvailability, ...tire } = item;

        tire = {
          ...tire,
          isOnSale: Boolean(tire.promotion.promoType || tire.isOnSale),
          qtySelectOptions,
          ...mapTireDeliveryOptions(deliveryAvailability),
        };

        const tireItemsByQty = qtySelectOptions.map((_cartQty) => {
          const cartItemTypeId = CART_ITEM_TYPE.tire;
          const venomApptServiceTypeId =
            _cartQty <= 2
              ? VENOM_APPT_SERVICE_TYPE.tireRotationFlats
              : VENOM_APPT_SERVICE_TYPE.tiresMoreThanTwo;

          const _promotion = {
            ...tire.promotion,
            promoType: _cartQty > 3 ? tire.promotion.promoType : null,
          };

          let _installSubtotal = 0;
          let _installTax = 0;
          let adjInstallPackage = tireInstallationPackage;
          if (_promotion.promoType) {
            adjInstallPackage = [
              ...adjInstallPackage,
              {
                itemId: EDGE_ITEM_IDS.ew,
                partNumber: "EW",
                description: "Lifetime Tire Maint. Certificate",
                price: roundMoney(
                  Math.max(12.0, Math.ceil(tire.price * 0.165))
                ),
                isTaxable: false,
                edgeItemType: "S",
              },
            ];

            if (_promotion.promoType == "focus_b2g2") {
              adjInstallPackage = [
                ...adjInstallPackage,
                {
                  itemId: EDGE_ITEM_IDS.labas,
                  partNumber: "LABAS",
                  description: "Wheel Alignment Service",
                  price: roundMoney(125.0),
                  isTaxable: false,
                  edgeItemType: "S",
                },
              ];
            }
          }

          const _installPackage = adjInstallPackage.map((installItem) => {
            const itemSubtotal =
              installItem.itemId !== EDGE_ITEM_IDS.labas
                ? roundMoney(installItem.price * _cartQty)
                : roundMoney(installItem.price);

            const itemTax = installItem.isTaxable
              ? roundMoney(itemSubtotal * taxRate)
              : 0;
            _installSubtotal = roundMoney(_installSubtotal + itemSubtotal);
            _installTax = roundMoney(_installTax + itemTax);
            return {
              ...installItem,
              itemSubtotal,
              itemTax,
            };
          });

          let _instantRebateDiscountsTotal = roundMoney(
            tire.instantRebateAmount * _cartQty
          );

          if (_promotion.promoType) {
            _instantRebateDiscountsTotal = ["focus_b2g2"].includes(
              _promotion.promoType
            )
              ? Math.min(300, roundMoney(tire.price * 2))
              : tire.price;

            if (_cartQty > 4) {
              _instantRebateDiscountsTotal +=
                tire.instantRebateAmount * (_cartQty - 4);
            }
            _instantRebateDiscountsTotal = roundMoney(
              _instantRebateDiscountsTotal
            );
          }

          const _tiresSubtotal = roundMoney(tire.price * _cartQty);
          const _tiresTax = roundMoney(
            (_tiresSubtotal - _instantRebateDiscountsTotal) * taxRate
          );

          let _mailInRebateTotal = 0;
          const _mailInRebates = tire.mailInRebates.filter((rebate) => {
            const isEligible = Boolean(_cartQty >= rebate.requiredQty);
            if (isEligible) {
              _mailInRebateTotal = roundMoney(
                _mailInRebateTotal + rebate.rebateAmount
              );
            }
            return isEligible;
          });

          const _totalDiscountsAvailable = roundMoney(
            _instantRebateDiscountsTotal + _mailInRebateTotal
          );

          const _subtotal = roundMoney(_tiresSubtotal + _installSubtotal);
          const _packageSubtotal = roundMoney(
            _subtotal - _totalDiscountsAvailable
          );
          const _tax = roundMoney(_tiresTax + _installTax);

          const stf = roundMoney(
            Math.min(tireSTF.percentageRate * tire.price, tireSTF.maximumPrice)
          );

          const _stfTotal = roundMoney(stf * _cartQty);
          const _fetTotal = roundMoney(tire.fet * _cartQty);

          const _invoicePrice = roundMoney(
            _subtotal +
              _tax +
              _stfTotal +
              _fetTotal -
              _instantRebateDiscountsTotal
          );

          const availabilityByQty = Object.assign(
            {},
            ...Object.entries(
              tire.deliveryOptionsByQty.find((i) => i.qtyNeeded === _cartQty)
            ).map(([key, val]) => ({ [`_${key}`]: val }))
          );

          const removeFromCart = () => {
            setCartItems(
              getCartItems().filter(
                ({ itemId = null }) => tire.itemId !== itemId
              )
            );
          };

          const updateQty = (newQty) => {
            tire.qtyController(newQty).addToCart(false);
          };

          const withQtyCartItem = {
            ...tire,
            _cartQty,
            cartItemTypeId,
            venomApptServiceTypeId,
            _tiresSubtotal,
            _tiresTax,
            _installPackage,
            _installSubtotal,
            _installTax,
            _instantRebateDiscountsTotal,
            _mailInRebates,
            _mailInRebateTotal,
            _totalDiscountsAvailable,
            _subtotal,
            _packageSubtotal,
            _tax,
            stf,
            _stfTotal,
            _fetTotal,
            _invoicePrice,
            _promotion,
            ...availabilityByQty,
            removeFromCart,
            updateQty,
          };

          const itemIsInCart = () => {
            return getCartItems().some((i) => i?.itemId == _tire.itemId);
          };

          const addToCart = async (showModal = true) => {
            const cartItem = {
              ..._tire,
              ...withQtyCartItem,
            };
            let cartItems = getCartItems();

            const existsIndex = cartItems.findIndex(
              (i) => i?.itemId == _tire.itemId
            );

            if (existsIndex >= 0) {
              cartItems[existsIndex] = cartItem;
            } else {
              cartItems = [...cartItems, cartItem];
            }

            if (showModal) {
              ModalStore.getState().CartDrawer.openModal();
            }

            await setCartItems(cartItems);
          };

          return {
            ...withQtyCartItem,
            itemIsInCart,
            addToCart,
          };
        });

        const _tire = Object.assign(tire, {
          qtyController: (selectedQty) =>
            tireItemsByQty.find((i) => i._cartQty == selectedQty),
        });

        return _tire;
      }
    );

    return cartItemTires;
  };

  return {
    ...initialState,
    getActiveTireItems,
  };
});

export default TireItemsStore;
