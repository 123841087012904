import VyperLandingPage from "./template/vyper-landing-page";
import APP_ENV from "../../../appEnvironment";
import { STATIC_CART_ITEMS } from "../../../static/cart-items";

export const VYPER_FREE_OIL_CHANGE = {
  promotionDirectory: "/free-oil-change",
  edgeItemId: 98480,
  activePromotions: [
    {
      endPoint: "hanford-ca",
      stores: ["1052"],
      story:
        "We have a brand new location in Hanford and are excited to join your community. A $0 oil change is our present to you!",
    },
    {
      endPoint: "temecula-ca",
      stores: ["1053"],
      story:
        "We have a brand new location in Temecula and are excited to join your community. A $0 oil change is our present to you!",
    },
  ],
};

const VyperFreeOilChange = () => {
  const PROMO_IMAGES = {
    1: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/vyper-free-oil-change-2024.png",
  };

  return (
    <VyperLandingPage
      lineOneText="Get Your FREE"
      lineTwoText="Oil Change Now"
      lineThreeText="*Includes Tire Rotation & Vehicle Inspection"
      buttonText="Get FREE Oil Change"
      promotionImage={PROMO_IMAGES[APP_ENV.appBrandId] ?? PROMO_IMAGES[1]}
      promotionCartItem={STATIC_CART_ITEMS.FREE_OIL_CHANGE}
      activePromotions={VYPER_FREE_OIL_CHANGE.activePromotions}
    />
  );
};

export default VyperFreeOilChange;
