import { Typography, Grid, Box } from "@material-ui/core";
import APP_ENV from "../../appEnvironment";

const BRAND_INFO_GRAPHICS = {
  1: [
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/four-states.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/200-locations.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/rating.png",
  ],
  2: [
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/four-states.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/200-locations.png",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/rating.png",
  ],
  3: [
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/landing-pages/atd-icon.jpg",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/atd/landing-pages/100-locations.jpg",
    "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitev4regions/bigbrandtire/landing-pages/rating.png",
  ],
};

const { theme } = APP_ENV;

const AboutBrandInfoGraphics = () => {
  const images = BRAND_INFO_GRAPHICS[APP_ENV.appBrandId];

  return (
    <Box style={{ textAlign: "center" }}>
      <Typography
        variant="h3"
        style={{
          marginBottom: 25,
          color: theme.palette.footer2.main,
        }}
      >
        {`About ${APP_ENV.appBrandName}`}
      </Typography>

      <Grid container spacing={5} style={{ justifyContent: "center" }}>
        {images.map((image, i) => (
          <Grid item xs={6} sm={6} md={4} key={i}>
            <img
              style={{
                height: 175,
                width: "100%",
                objectFit: "contain",
              }}
              src={image}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AboutBrandInfoGraphics;
