import { useEffect } from "react";

const OlarkChat = {
  expand: () => window.olark("api.box.expand"),
  hide: () => window?.olark && window?.olark("api.box.hide"),
  shrink: () => window?.olark && window?.olark("api.box.shrink")
};

export const useOlarkChat = () => {
  useEffect(() => {
    window.olarkReact = function () {
      (function (o, l, a, r, k, y) {
        if (o.olark) return;
        r = "script";
        y = l.createElement(r);
        r = l.getElementsByTagName(r)[0];
        y.async = 1;
        y.src = "//" + a;
        r.parentNode.insertBefore(y, r);
        y = o.olark = function () {
          k.s.push(arguments);
          k.t.push(+new Date());
        };
        y.extend = function (i, j) {
          y("extend", i, j);
        };
        y.identify = function (i) {
          y("identify", (k.i = i));
        };
        y.configure = function (i, j) {
          y("configure", i, j);
          k.c[i] = j;
        };
        k = y._ = { s: [], t: [+new Date()], c: {}, l: a };
      })(window, document, "static.olark.com/jsclient/loader.js");
      /* Add configuration calls bellow this comment */
      olark.configure("system.hb_position", "right");
      olark.configure("system.hb_chatbox_size", "md");
      olark.configure("system.hb_show_button_text", false);
      const OLARK_SITE_ID = "1537-637-10-2558";
      olark.identify(OLARK_SITE_ID);
      window.olarkReact.olarkWasLaunched = true;
    };

    window.olarkReact();

    setTimeout(OlarkChat.shrink, 1000);
  }, []);
};

export default OlarkChat;
