import { useEffect } from "react";
import { Box, Button } from "@material-ui/core";
import useZState from "./util/useZState";
import { useHistory } from "react-router";
import SelectVehicle from "../components/reusable/conditional-selection/SelectVehicle";
import SelectTireSizeByVehicle from "../components/reusable/conditional-selection/SelectTireSizeByVehicle";
import SelectCustomTireSize from "../components/reusable/conditional-selection/SelectCustomTireSize";

const useVehicleTireSizeSelectControl = ({ closeModal, isOpen = false }) => {
  const history = useHistory();

  const defaultState = {
    currentStep: 0,
    tabIndex: 0,
  };

  const [getState, setState] = useZState(defaultState);

  useEffect(() => {
    if (!isOpen) {
      setState(defaultState);
    }
  }, [isOpen]);

  const handleFinalSelection = ({ tireSizeEndPoint = null }) => {
    closeModal();
    history.push(`/search/1/${tireSizeEndPoint}`);
  };

  const selectVehicleForTireSize = {
    key: "SELECT_VEHICLE_FOR_TIRE_SIZE",
    tabLabel: "Shop By Vehicle",
    sectionTitle: "Select Vehicle",
    sectionDesc: "All we need is the Year, make, and model to get started.",
    component: SelectVehicle,
    action: () => setState({ currentStep: getState().currentStep + 1 }),
  };

  const selectTireSizeByVehicle = {
    key: "SELECT_TIRE_SIZE_BY_VEHICLE",
    tabLabel: "",
    sectionTitle: "Select Your Tire Size",
    sectionDesc: "",
    component: SelectTireSizeByVehicle,
    action: handleFinalSelection,
  };

  const selectCustomTireSize = {
    key: "SELECT_CUSTOM_TIRE_SIZE",
    tabLabel: "Shop By Tire Size",
    sectionTitle: "Select Tire Size",
    sectionDesc: "Select your width, ratio, and rim.",
    component: SelectCustomTireSize,
    action: handleFinalSelection,
  };

  const { currentStep, tabIndex } = getState();
  let activeStep = selectTireSizeByVehicle;
  let tabs = [
    {
      tabLabel: "Back",
      onClick: () => setState({ currentStep: getState().currentStep - 1 }),
      isActive: true,
      isBackButton: true,
    },
    {
      tabLabel: "",
      onClick: () => null,
      isActive: false,
      isBackButton: false,
    },
  ];

  if (currentStep == 0) {
    tabs = [selectVehicleForTireSize, selectCustomTireSize].map(
      ({ tabLabel }, i) => ({
        tabLabel,
        onClick: () => setState({ tabIndex: i }),
        isActive: getState().tabIndex == i,
        isBackButton: false,
      })
    );
    activeStep =
      tabIndex == 0 ? selectVehicleForTireSize : selectCustomTireSize;
  }

  return {
    currentStep,
    tabIndex,
    allSteps: [
      selectVehicleForTireSize,
      selectTireSizeByVehicle,
      selectCustomTireSize,
    ],
    tabs,
    activeStep,
    setTabIndex: (val) => setState({ tabIndex: val }),
  };
};

export const VehicleTireSizeSelectComponentControl = ({
  allSteps,
  activeStep,
}) => {
  const Components = Object.assign(
    {},
    ...allSteps.map(({ key, component, action }) => {
      return { [key]: component(action) };
    })
  );

  const { component, handleSubmit } = Components[activeStep.key];

  return (
    <>
      <Box>{component}</Box>
      {handleSubmit && (
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            style={{ width: 300, height: 50 }}
          >
            Submit
          </Button>
        </Box>
      )}
    </>
  );
};

export default useVehicleTireSizeSelectControl;
